import React from 'react'
import Navbar from '../Components/Navbar'
import AboutUsBanner from '../Components/AboutUsBanner'
import OurGoal from '../Components/OurGoal'
import OurNumber from '../Components/OurNumber'
import Footer from '../Components/Footer'

const AboutUs = () => {
  return (
    <div>
      <Navbar />

      <AboutUsBanner />

      <OurGoal />

      <OurNumber  />
      <Footer /> 
    </div>
  )
}

export default AboutUs

import React from 'react'
import AboutUsBannerImg from '../images/AboutUs/AboutUsBanner.png'
import AboutUsBannerMBImg from '../images/AboutUs/AboutUsBannerMB.png'

const AboutUsBanner = () => {
  return (
    <div className='w-full max-w-[1460px] m-auto my-[100px] max_768:my-[60px] max_500:my-[50px] max_xl1460:max-w-[1024px] max_1024:px-10 max_500:px-5'>
      <div className='w-full relative max_720:hidden'>
        <div className='w-[50%] max_xl1460:w-[60%] max_980:w-[60%] max_768:w-[80%]'>
          <img src={AboutUsBannerImg} alt='About_us' />
          <div className='absolute right-[100px] p-[40px] max_xl1460:p-[20px] bottom-[45px] max_xl1460:bottom-[14px] max_xl1460:right-[10px] max_1024:right-[30px] max_xl1460: bg-white w-[50%] max_xl1460:w-[60%] max_980:bottom-[-80px] max_768:p-4 max_980:w-[70%] max_768:right-[10px]' style={{
            boxShadow: '2px 2px 8px 0px #00000033'
          }} data-aos="flip-right">
            <p className='Text36Monts text-Primary'>About Us</p>
            <p className='Text18Sans mt-5 max_xl1460:mt-3'>At Trisol, we are committed to excellence in manufacturing and supplying premium lubricant engine oil. With a relentless focus on innovation and quality, we strive to exceed customer expectations, ensuring peak performance and reliability for every engine. Discover our dedication to precision engineering and unmatched expertise as we continue to drive the industry forward.</p>
          </div>
        </div>
      </div>
      <div className='hidden max_720:block' style={{
        boxShadow: '2px 2px 8px 0px #00000033'
      }}>
        <div className='flex flex-col'>
          <div>
            <img src={AboutUsBannerMBImg} alt='About_us' />
          </div>
          <div className='p-5'>
            <p className='Text36Monts text-Primary'>About Us</p>
            <p className='Text18Sans mt-5 max_xl1460:mt-3'>At Trisol, we are committed to excellence in manufacturing and supplying premium lubricant engine oil. With a relentless focus on innovation and quality, we strive to exceed customer expectations, ensuring peak performance and reliability for every engine. Discover our dedication to precision engineering and unmatched expertise as we continue to drive the industry forward.</p>
          </div>
        </div>

      </div>
      <div className='flex flex-col gap-[30px] mt-[100px] max_768:mt-[60px] max_500:mt-[50px]'>
        <p className='Text18Sans'>Welcome to Trisol, a leading manufacturer and supplier of lubricants and automobile oils. Our dedication to innovation, excellence, and sustainability ensures superior products globally. Through advanced technology and research, we develop formulations for optimal lubrication, engine performance, and equipment longevity, covering various applications such as engine oils, transmission fluids, and hydraulic oils.</p>
        <p className='Text18Sans'>Quality is paramount at Trisol. We source premium materials, adhere to rigorous manufacturing processes, and surpass industry standards and OEM specifications. Our state-of-the-art facilities and skilled professionals ensure quality and consistency in every batch.</p>
        <p className='Text18Sans'>Committed to environmental stewardship, we invest in eco-friendly technologies, reduce waste, and optimize energy efficiency. By prioritizing sustainability, we contribute to a cleaner planet and help customers achieve their environmental goals.</p>
        <p className='Text18Sans'>Building enduring partnerships based on trust and reliability, our dedicated team provides personalized support and technical expertise. Whether you're an automotive manufacturer, distributor, or end-user, Trisol is your reliable partner for premium lubricants and oils. Contact us to explore how we can enhance your operations.</p>
      </div>
    </div>
  )
}

export default AboutUsBanner

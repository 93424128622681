import React from 'react'
import GoalImg from '../images/Homepage/TrisolGoal.png'
import ButtonComp from './ButtonComp'


const CommonTwoPartComponent = ({ text, head1, head2 }) => {
  return (
    <div className='flex justify-center gap-[62px] max-w-[1054px] max_xl1460:max-w-[980px] m-auto items-center pt-10 mb-20 max_500:mb-10 max_500:pt-5 max_1024:px-10 max_500:px-5 max_1024:gap-12 max_720:flex-col max_720:gap-4'>
      <div className='w-[48%] max_720:w-[72%] max_500:w-[90%]' data-aos="zoom-in">
        <img src={GoalImg} alt='goal_img' />
      </div>
      <div data-aos="zoom-in" className='flex flex-col gap-[30px] max_1024:gap-4 w-[48%] max_720:items-center max_720:w-[72%] max_500:w-[90%] max_500:items-start'>
        <p className='Text36Monts text-Primary'>{head1}  <span className='text-Secondary_Primary'>{head2}</span></p>
        <p className='Text18Sans max_720:text-center max_500:text-start'>{text}</p>
        <ButtonComp text="Read more" />
      </div>
    </div>
  )
}

export default CommonTwoPartComponent

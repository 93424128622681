import React, { useEffect, useRef } from 'react'
import AnimImg from "../images/About/LeftImg.png"
import AOS from "aos";
import "aos/dist/aos.css";
import { useIsVisible } from '../hooks/useVisible';

const CommonCard = ({ data,height,storeIndex,setStoreIndex,index }) => {
  const viewRef = useRef()
  const isInView = useIsVisible(viewRef)
  useEffect(() => {
    if(isInView)
    AOS.init();
    AOS.refresh();
    AOS.refreshHard()
  }, [isInView]);
  return (
    <div data-aos="fade-right" className={`card-container relative p-[30px] max_xl1460:p-5 max_1024:p-4 rounded-[5px] border-[1px] border-Light_white max_400:p-4 ${height ? 'h-[420px] max_500:h-[370px]' : 'max_980:min-h-[300px] overflow-x-hidden'}`} onMouseEnter={()=>setStoreIndex(index)} onMouseLeave={()=>setStoreIndex(null)} ref={viewRef}>
      <div className='h-[76px] relative'>
      {React.cloneElement(data.image, { color: storeIndex === index ?'#B47922' : '#004E72'})}
      <div className='absolute right-[-50px] top-[-30px]' style={{ transition: 'transform 0.3s', transform: `translateX(${storeIndex === index ? '-20px' : '0'})` }}>
        <img src={AnimImg} alt='slide' />
      </div>
      </div>
      <p className='text-[20px] font-MontserratF font-medium mt-[30px] max_1024:text-[18px]'>{data.title}</p>
      <p className='Text18Sans mt-5 max_xl1460:text-[17px]'>{data.text}</p>
    </div>
  )
}

export default CommonCard

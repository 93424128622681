import React, { useState } from 'react'
import CommonCard from './CommonCard'
import OverV from '../images/AboutUs/OurV.png'
import OverM from '../images/AboutUs/OurM.png'
import { useWindowWidthObserver } from '../hooks/useWindowWidth'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules';
import { GlobalIcon, InnovativeDriveIcon, OptimalIcon, QualityIcon } from '../images/icons'


const OurGoal = () => {
  const windowWidth = useWindowWidthObserver();
  const [storeIndex,setStoreIndex] = useState(null)
  const [infoData] = useState([
    {
      id: 1,
      image: <QualityIcon />,
      title: 'Mission Excellence',
      text: 'Our primary goal at Trisol is to set a new benchmark for excellence within the lubricant engine oil industry.'
    },
    {
      id: 2,
      image: <InnovativeDriveIcon />,
      title: 'Innovation Drive',
      text: 'Our aim is to continually innovate, research, and develop advanced lubricant solutions that surpass customer expectations.'
    },
    {
      id: 3,
      image: <OptimalIcon />,
      title: 'Optimal Performance',
      text: 'We strive to create products that optimize engine performance, ensuring peak efficiency and reliability.'
    },
    {
      id: 4,
      image: <GlobalIcon />,
      title: 'Global Preference',
      text: 'With a focus on sustainability and customer satisfaction, we aspire to become the preferred choice for lubricant engine oil worldwide.'
    },
  ])
  return (
    <div className='w-full max-w-[1460px] m-auto my-[100px] max_xl1460:max-w-[980px] max_1024:px-10 max_500:px-5'>
      <p className='Text36Monts text-Primary text-center'>How <span className='text-Secondary_Primary'>We Work</span></p>
      <p className='Text18Sans text-center mt-[30px]'>At Trisol, our goal is simple yet ambitious: to be the premier manufacturer and supplier of lubricants and automobile oils, setting the standard for quality, innovation, and sustainability in the industry.</p>
      <div className='grid grid-cols-4 gap-[30px] max_xl1460:gap-5 mt-[50px] max_1024:gap-4 max_980:hidden'>
        {infoData.map((data, index) => {
          return (
            <React.Fragment key={index} >
              <CommonCard data={data} storeIndex={storeIndex} setStoreIndex={setStoreIndex} index={index} />
            </React.Fragment>
          )
        })}
      </div>

      {/* TABLATE MOBILE DESIGN */}
      <div className='hidden max_980:block'>
        <div className='flex justify-center max_500:px-5 py-[50px] max_500:py-[40px] max_350:mx-0'>
          <Swiper
            slidesPerView={windowWidth > 500 ? 2 : 1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiperInfo"
            loop={true}
          >
            {infoData.map((data, idx) => (
              <SwiperSlide key={idx} style={{ background: "transparent" }}>
                <CommonCard data={data} storeIndex={storeIndex} setStoreIndex={setStoreIndex} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <p className='Text18Sans text-center mt-[50px]'>At Trisol, these four pillars—quality excellence, innovation leadership, environmental stewardship, and customer satisfaction—guide our actions and drive us towards achieving our goal of being the preferred choice for lubricants and automobile oils worldwide.</p>

      <div className='grid grid-cols-2 gap-[30px] mt-[100px] max_768:hidden' >
        <div className='border-[1px] border-Light_white p-[30px] max_1024:p-5 card-container' data-aos="zoom-in">
          <div className='flex gap-5 items-center'>
            <div className='w-[70px] h-[70px] border-[1px] border-Primary F_JC_IC rounded-full'>
              <img src={OverV} alt='Our_Vision' />
            </div>
            <p className='Text28Monts text-[26px] text-Primary'>Our Vision</p>
          </div>
          <p className='Text18Sans mt-5'>To be the foremost provider of premium lubricants and oils, renowned for innovation, sustainability, and unmatched service, driving excellence and reliability in the automotive industry worldwide.</p>
        </div>
        <div className='border-[1px] border-Light_white p-[30px] max_1024:p-5 card-container' data-aos="zoom-in">
          <div className='flex gap-5 items-center'>
            <div className='w-[70px] h-[70px] border-[1px] border-Primary F_JC_IC rounded-full'>
              <img src={OverM} alt='Our_Mission' />
            </div>
            <p className='Text28Monts text-[26px] text-Primary'>Our Mission</p>
          </div>
          <p className='Text18Sans mt-5'>At Trisol, we're dedicated to superior lubricants and oils that optimize performance, enhance efficiency, and extend vehicle and machinery lifespan. With cutting-edge technology, rigorous quality control, and a commitment to sustainability, we exceed expectations, forging enduring partnerships based on trust, reliability, and mutual success.</p>
        </div>
      </div>

      <div className='hidden max_768:block'>
        <div className='mt-10'>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiperInfo"
            loop={true}
          >

            <SwiperSlide style={{ background: "transparent" }}>
              <div className='border-[1px] border-Light_white p-[30px] max_500:p-6'>
                <div className='flex gap-5 items-center'>
                  <div className='w-[70px] h-[70px] border-[1px] border-Primary F_JC_IC rounded-full'>
                    <img src={OverV} alt='Our_Vision' />
                  </div>
                  <p className='Text28Monts text-[26px] text-Primary'>Our Vision</p>
                </div>
                <p className='Text18Sans mt-5'>To be the foremost provider of premium lubricants and oils, renowned for innovation, sustainability, and unmatched service, driving excellence and reliability in the automotive industry worldwide.</p>
              </div>

            </SwiperSlide>
            <SwiperSlide>
              <div className='border-[1px] border-Light_white p-[30px] max_500:p-6'>
                <div className='flex gap-5 items-center'>
                  <div className='w-[70px] h-[70px] border-[1px] border-Primary F_JC_IC rounded-full'>
                    <img src={OverM} alt='Our_Mission' />
                  </div>
                  <p className='Text28Monts text-[26px] text-Primary'>Our Mission</p>
                </div>
                <p className='Text18Sans mt-5'>At Trisol, we're dedicated to superior lubricants and oils that optimize performance, enhance efficiency, and extend vehicle and machinery lifespan. With cutting-edge technology, rigorous quality control, and a commitment to sustainability, we exceed expectations, forging enduring partnerships based on trust, reliability, and mutual success.</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

    </div>
  )
}

export default OurGoal

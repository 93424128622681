import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import SingleProdComp from '../Components/SingleProdComp'
import SwipeProduct from '../Components/SwipeProduct'

const SingleProductPage = () => {
  return (
    <>
    <Navbar />
    <SingleProdComp />
    <SwipeProduct />
    <Footer />
    </>
    
  )
}

export default SingleProductPage

import React, { useRef, useState } from 'react'
import Logo from "../images/Navbar/LogoFooter.png"
import ArrowDown from "../images/Homepage/Vector 9.png"
import { Email, FBIcon, INIcon, LNIcon, Location, Phone, WPIcon, YTIcon } from '../images/icons'
import useOutsideClick from '../hooks/useClickoutside'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  const [quickLink, setQuickLink] = useState([
    {
      name: 'Home',
      link: '/'
    },
    {
      name: 'Products',
      link: '/products'
    },
    {
      name: 'Gallery',
      link: '/gallary'
    }
  ])
  const [company, setCompany] = useState([
    {
      name: 'About Us',
      link: '/about-us'
    },
    {
      name: 'Career',
      link: '/career'
    },
    {
      name: 'Contact Us',
      link: '/contact-us'
    }
  ])
  const linkRef = useRef()
  const companyRef = useRef()
  const contactRef = useRef()
  
  const [socialIndex, setSocialIndex] = useState(null)
  const [linkOpen, setLinkOpen] = useState(false)
  const [companyOpen, setCompanyOpen] = useState(false)
  const [contactOpen, setContactOpen] = useState(false)
  
  useOutsideClick(linkRef, () => setLinkOpen(false))
  useOutsideClick(companyRef, () => setCompanyOpen(false))
  useOutsideClick(contactRef, () => setContactOpen(false))
  return (
    <div className='w-full bg-Primary pt-[100px] pb-[40px] max_768:mt-[60px] max_500:mt-[50px]'>

      <div className='w-full max-w-[1460px] m-auto max_xl1460:max-w-[1024px] max_1024:max-w-[768px] max_768:px-10 max_500:px-5'>
        <div className='flex justify-between max_768:gap-14 max_720:flex-col max_500:gap-6'>

          <div>
            <div className='max_xl1460:w-[90%] max_768:w-[100%]'>
              <img src={Logo} alt='Logo' />
            </div>
            <p className='Text18Sans text-white max-w-[198px] mt-[30px]'>Powering Performance,
              Protecting Engines.</p>
          </div>
          <div className=''>

            <div className='flex gap-[100px] max_xl1460:gap-8 max_1024:flex-wrap max_768:gap-5'>
              {/* SEC_1 */}
              <div className='flex flex-col gap-5 max_768:w-[210px] max_720:!w-[48%] max_500:w-[100%] max_500:gap-3 max_500:hidden'>
                <p className='Text28Monts text-[26px] text-Secondary_Primary'>Quick links</p>
                {quickLink.map((item, index) => {
                  return (
                    <React.Fragment key={index}><p className='Text18Sans text-white cursor-pointer hover:text-Secondary_Primary w-max' onClick={()=>navigate(item.link)}>{item.name}</p></React.Fragment>
                  )
                })}
              </div>
              {/* MOBILE */}
              <div className='hidden max_500:block w-full'>
                <div className={`z-30 relative bg-[#004464] px-5 py-[11px] flex items-center justify-between w-full rounded-[5px] ${linkOpen ? 'border-b-[1px] border-Secondary_Primary' : ''}`} 
                onClick={() => setLinkOpen(!linkOpen)} ref={linkRef}>
                  <p className='Text28Monts text-[26px] text-Secondary_Primary '>Quick links</p>
                  <div>
                    <img src={ArrowDown} alt='Arrow' />
                  </div>
                  {linkOpen && (
                    <div className='z-30 absolute left-0 top-[60px] flex flex-col gap-4 bg-[#004464] w-full px-5 py-3 rounded-bl-[5px] rounded-br-[5px]' >
                      {quickLink.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <p className='text-[16px] !text-white cursor-pointer hover:text-Secondary_Primary w-max' onClick={()=>navigate(item.link)}>{item.name}</p></React.Fragment>
                        )
                      })}
                    </div>
                  )}

                </div>
              </div>
              {/* SEC-2 */}
              <div className='flex flex-col gap-5 max_720:w-[48%] max_500:w-[100%] max_500:gap-3 max_500:hidden'>
                <p className='Text28Monts text-[26px] text-Secondary_Primary'>Company</p>
                {company.map((item, index) => {
                  return (
                    <React.Fragment key={index}><p className='Text18Sans text-white cursor-pointer hover:text-Secondary_Primary w-max' onClick={()=>navigate(item.link)}>{item.name}</p></React.Fragment>
                  )
                })}
              </div>
              {/* MOBILE */}
              <div className='hidden max_500:block w-full'>
                <div className={` relative bg-[#004464] px-5 py-[11px] flex items-center justify-between w-full rounded-[5px] ${companyOpen ? 'border-b-[1px] border-Secondary_Primary' : ''}`} onClick={() => setCompanyOpen(!companyOpen)} ref={companyRef}>
                  <p className='Text28Monts text-[26px] text-Secondary_Primary '>Company</p>
                  <div>
                    <img src={ArrowDown} alt='Arrow' />
                  </div>
                  {companyOpen && (
                    <div className='z-30 absolute left-0 top-[60px] flex flex-col gap-4 bg-[#004464] w-full px-5 py-3 rounded-bl-[5px] rounded-br-[5px]' >
                      
                      {company.map((item, index) => {
                        return (
                          <React.Fragment key={index}><p className='text-[16px] !text-white cursor-pointer hover:text-Secondary_Primary w-max' onClick={()=>navigate(item.link)}>{item.name}</p></React.Fragment>
                        )
                      })}
                    </div>
                  )}

                </div>
              </div>
              {/* SEC-3 */}
              <div className='flex flex-col gap-5 max-w-[319px] max_1024:max-w-[235px] max_768:max-w-[210px] max_720:max-w-[48%] max_500:w-[100%] max_500:max-w-[100%] max_720:w-[48%] max_500:gap-3 max_500:hidden'>
                <p className='Text28Monts text-[26px] text-Secondary_Primary'>For Contact</p>
                <div className='flex gap-[30px] max_xl1460:gap-4 items-center w-max' onMouseEnter={() => setSocialIndex(6)} onMouseLeave={() => setSocialIndex(null)}>
                  <Email color={`${socialIndex === 6 ? "#B47922" : "#fff"}`} />
                  <p className={`Text18Sans cursor-pointer ${socialIndex === 6 ? "text-Secondary_Primary" : "text-[#fff]"}`}>Trisol@gmail.com</p>
                </div>
                <div className='flex gap-[30px] max_xl1460:gap-4 items-center w-max' onMouseEnter={() => setSocialIndex(7)} onMouseLeave={() => setSocialIndex(null)}>
                  <Phone color={`${socialIndex === 7 ? "#B47922" : "#fff"}`} />
                  <p className={`Text18Sans cursor-pointer ${socialIndex === 7 ? "text-Secondary_Primary" : "text-[#fff]"}`}>063545 27074</p>
                </div>
                <div className='flex gap-[30px] max_xl1460:gap-4 items-start w-max' onMouseEnter={() => setSocialIndex(8)} onMouseLeave={() => setSocialIndex(null)}>
                  <Location color={`${socialIndex === 8 ? "#B47922" : "#fff"}`} />
                  <p className={`Text18Sans  cursor-pointer max-w-[264px] max_768:max-w-[210px] max_500:max-w-[245px] ${socialIndex === 8 ? "text-Secondary_Primary" : "text-[#fff]"}`}>Block No. E-37, Hindva Dreams, Dhoran Pardi Gaypagla Road, Kamrej, Surat, Gujarat-394155</p>
                </div>
              </div>
              {/* MOBILE */}

              <div className='hidden max_500:block w-full'>
                <div className={` relative bg-[#004464] px-5 py-[11px] flex items-center justify-between w-full rounded-[5px] ${contactOpen ? 'border-b-[1px] border-Secondary_Primary' : ''}`} onClick={() => setContactOpen(!contactOpen)} ref={contactRef}>
                  <p className='Text28Monts text-[26px] text-Secondary_Primary '>For Contact</p>
                  <div>
                    <img src={ArrowDown} alt='Arrow' />
                  </div>
                  {contactOpen && (
                    <div className='z-30 absolute left-0 top-[60px] flex flex-col gap-4 bg-[#004464] w-full px-5 py-3 rounded-bl-[5px] rounded-br-[5px]' >
                      <div className='flex gap-[30px] max_xl1460:gap-4 items-center w-max' onMouseEnter={() => setSocialIndex(6)} onMouseLeave={() => setSocialIndex(null)}>
                        <Email color={`${socialIndex === 6 ? "#B47922" : "#fff"}`} />
                        <p className={`Text18Sans cursor-pointer ${socialIndex === 6 ? "text-Secondary_Primary" : "text-[#fff]"}`}>Trisol@gmail.com</p>
                      </div>
                      <div className='flex gap-[30px] max_xl1460:gap-4 items-center w-max' onMouseEnter={() => setSocialIndex(7)} onMouseLeave={() => setSocialIndex(null)}>
                        <Phone color={`${socialIndex === 7 ? "#B47922" : "#fff"}`} />
                        <p className={`Text18Sans cursor-pointer ${socialIndex === 7 ? "text-Secondary_Primary" : "text-[#fff]"}`}>063545 27074</p>
                      </div>
                      <div className='flex gap-[30px] max_xl1460:gap-4 items-start w-max' onMouseEnter={() => setSocialIndex(8)} onMouseLeave={() => setSocialIndex(null)}>
                        <Location color={`${socialIndex === 8 ? "#B47922" : "#fff"}`} />
                        <p className={`Text18Sans  cursor-pointer max-w-[264px] max_768:max-w-[210px] max_500:max-w-[245px] ${socialIndex === 8 ? "text-Secondary_Primary" : "text-[#fff]"}`}>Block No. E-37, Hindva Dreams, Dhoran Pardi Gaypagla Road, Kamrej, Surat, Gujarat-394155</p>
                      </div>
                    </div>
                  )}

                </div>
              </div>
              {/* SEC-4 */}
              <div className='flex flex-col gap-5 max_720:w-[48%] max_500:gap-3'>
                <p className='Text28Monts text-[26px] text-Secondary_Primary'>Follow Us</p>
                <div className='grid grid-cols-3 gap-5 max_xl1460:gap-2 max_1024:grid-cols-5 max_768:grid-cols-3 max_720:w-[65%] max_500:w-full max_720:grid-cols-5 max_500:gap-12'>
                  <div className='flex w-[50px] max_xl1460:w-[40px] max_xl1460:h-[40px] cursor-pointer h-[50px] border-[1px] border-white justify-center items-center' onMouseEnter={() => { setSocialIndex(1) }} onMouseLeave={() => setSocialIndex(null)}>
                    <FBIcon color={`${socialIndex === 1 ? '#B47922' : '#fff'}`} />
                  </div>
                  <div className='flex w-[50px] max_xl1460:w-[40px] max_xl1460:h-[40px] cursor-pointer h-[50px] border-[1px] border-white justify-center items-center' onMouseEnter={() => { setSocialIndex(2) }} onMouseLeave={() => setSocialIndex(null)}>
                    <WPIcon color={`${socialIndex === 2 ? '#B47922' : '#fff'}`} />
                  </div>
                  <div className='flex w-[50px] max_xl1460:w-[40px] max_xl1460:h-[40px] cursor-pointer h-[50px] border-[1px] border-white justify-center items-center' onMouseEnter={() => { setSocialIndex(3) }} onMouseLeave={() => setSocialIndex(null)}>
                    <INIcon color={`${socialIndex === 3 ? '#B47922' : '#fff'}`} />
                  </div>
                  <div className='flex w-[50px] max_xl1460:w-[40px] max_xl1460:h-[40px] cursor-pointer h-[50px] border-[1px] border-white justify-center items-center' onMouseEnter={() => { setSocialIndex(4) }} onMouseLeave={() => setSocialIndex(null)}>
                    <LNIcon color={`${socialIndex === 4 ? '#B47922' : '#fff'}`} />
                  </div>
                  <div className='flex w-[50px] max_xl1460:w-[40px] max_xl1460:h-[40px] cursor-pointer h-[50px] border-[1px] border-white justify-center items-center' onMouseEnter={() => { setSocialIndex(5) }} onMouseLeave={() => setSocialIndex(null)}>
                    <YTIcon color={`${socialIndex === 5 ? '#B47922' : '#fff'}`} />
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>

        <div className='w-full h-[1px] bg-white mt-[100px] max_768:mt-[60px] max_500:mt-[50px]'></div>
        <p className='text-center Text18Sans text-white mt-[30px]'>© 2024 TRISOL LUBRICANTS. All rights reserved</p>

      </div>
    </div>
  )
}

export default Footer

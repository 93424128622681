import React, { useState } from 'react'
import Next from "../images/Products/NextIcon.png"


const ProductCard = ({item,handleClick,id,isSwipe}) => {
  const [hoverIndex,setHoverIndex] = useState(null)
  return (
    <div className={`${isSwipe ? 'p-5':'p-[30px]'} border-[1px] border-transparent hover:border-[#e1e1e1] rounded-[6px] hover:bg-[#fcfcfc] cursor-pointer`} data-aos="fade-up" onMouseEnter={()=>setHoverIndex(id)} onMouseLeave={()=>setHoverIndex(null)} onClick={() => handleClick(item.id)}>
      <div className='rounded-md'>
        <img src={item.image} alt='1st_img' className='rounded-md' />
      </div>
      <div className='flex items-center gap-4 mt-[30px] cursor-pointer w-fit' >
        <p className='text-[26px] font-medium font-MontserratF text-Primary max_xl1460:text-[22px] max_980:text-[22px] max_500:text-[18px] flex gap-4 items-center'>{item.name}<span className={`transition-transform duration-[0.3s] ease-[ease-out] ${hoverIndex === id ? 'translate-x-[10px]' :''}`}><img src={Next} alt='' /></span></p>
      </div>
    </div>
  )
}

export default ProductCard

import React from 'react'

const ButtonComp1 = ({ text, left,handleOnClick }) => {
  return (
   
    <button class="button" onClick={handleOnClick}>
      <span class="text">{text}</span>
      <div class="wave1"></div>
    </button>

  )
}

export default ButtonComp1

import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import ProdutsMain from '../Components/ProdutsMain'

const Products = () => {

  return (
    <div>
      <Navbar />
      <ProdutsMain />
      <Footer />
    </div>
  )
}

export default Products

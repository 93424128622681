import React from 'react'

const InputComponent = ({placeholder,value,name,handleOnChange,error}) => {
  return (
    <div className='relative'>
      <input className='w-full px-4 py-[11px] bg-[#F5F5F5] rounded-[5px] focus:outline-none Text18Sans text-gray' placeholder={placeholder} value={value} name={name} onChange={handleOnChange} />
      <p className='absolute text-[11px] text-red-500'>{error}</p>
    </div>
  )
}

export default InputComponent

import React, { useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const MapChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create map instance
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.logo.disabled = true;
    chart.hiddenState.properties.opacity = 0;
    chart.geodata = window.am4geodata_worldLow; // Use the globally available geodata
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ["AQ"];
    polygonSeries.useGeodata = true;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.polygon.fill = am4core.color("rgba(0, 78, 114, .7)");
    polygonTemplate.polygon.fillOpacity = 0.6;
    polygonTemplate.states.create("hover").properties.fill = chart.colors.getIndex(0);

    // Create map image series
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    imageSeries.mapImages.template.propertyFields.latitude = "latitude";
    imageSeries.mapImages.template.tooltipText = "{title}";
    imageSeries.mapImages.template.propertyFields.url = "url";

    let marker = imageSeries.mapImages.template.createChild(am4core.Image);
    marker.href = "https://ik.imagekit.io/trisol/assests/Trisol%20Marker.png?updatedAt=1716912068572";
    marker.width = 30;
    marker.height = 30;
    marker.nonScaling = true;
    marker.horizontalCenter = "middle";
    marker.verticalCenter = "bottom";

    let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle.radius = 2;
    circle.propertyFields.fill = "color";
    circle.events.on("inited", function(event) {
      animateCircle(event.target);
    });

    function animateCircle(circle) {
      circle.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut).events.on("animationended", function(event) {
        animateCircle(event.target.object);
      });
    }

    let colorSet = new am4core.ColorSet();
    imageSeries.data = [
      { title: "Myanmar", latitude: 21.916222, longitude: 95.955971, color: colorSet.next() },
      { title: "India", latitude: 20.593683, longitude: 78.962883, color: colorSet.next() },
      { title: "Iraq", latitude: 33.22319, longitude: 43.679291, color: colorSet.next() },
      { title: "Ghana", latitude: 7.9039267, longitude: -3.2744616, color: colorSet.next() },
      { title: "Taiwan", latitude: 23.6978, longitude: 120.9605, color: colorSet.next() },
      { title: "Russia", latitude: 61.52401, longitude: 105.318756, color: colorSet.next() },
      { title: "Somalia", latitude: 5.2420051, longitude: 41.807918, color: colorSet.next() },
      { title: "Libya", latitude: 26.2999273, longitude: 12.8370978, color: colorSet.next() },
      { title: "Kazakhstan", latitude: 48.1012954, longitude: 66.7780818, color: colorSet.next() },
      { title: "Pakistan", latitude: 30.3308401, longitude: 71.247499, color: colorSet.next() },
      { title: "Cambodia", latitude: 12.5433216, longitude: 104.8144914, color: colorSet.next() },
      { title: "Brazil", latitude: -10, longitude: -55, color: colorSet.next() },
      { title: "Australia", latitude: -27, longitude: 133, color: colorSet.next() },
      { title: "Canada", latitude: 61.0666922, longitude: -107.991707, color: colorSet.next() },
      { title: "United States of America", latitude: 38.673433, longitude: -100.67704, color: colorSet.next() }
    ];

    chartRef.current = chart;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  return <div id="chartdiv"  className='mb-[100px] w-[100%] h-[650px] max_980:h-[500px]  max_980:mb-10 max_500:h-[260px] max_500:mb-5' />;
};

export default MapChart;


import Extrem from '../images/Products/Product1/Extreme 4T.png'
import Ultra from '../images/Products/Product1/Ultra 4T.png'
import Rider from '../images/Products/Product1/Rider 4T.png'
import Premium from '../images/Products/Product1/Premium 4T.png'
import Max from '../images/Products/Product1/Max 4T.png'
import Advanced from '../images/Products/Product1/Advanced 4T.png'
import Syntech from '../images/Products/Product1/Syntec 4T.png'
import Scootex from '../images/Products/Product1/Scootex 4T.png'

import Extr1 from '../images/Products/Product1/Extreme1.png'
import Extr2 from '../images/Products/Product1/Extreme2.png'
import Extr3 from '../images/Products/Product1/Extreme3.png'
import Ultra1 from '../images/Products/Product1/Ultra1.png'
import Ultra2 from '../images/Products/Product1/Ultra2.png'
import Ultra3 from '../images/Products/Product1/Ultra3.png'
import Rider1 from '../images/Products/Product1/Rider1.png'
import Rider2 from '../images/Products/Product1/Rider2.png'
import Rider3 from '../images/Products/Product1/Rider3.png'
import Premium1 from '../images/Products/Product1/Premium1.png'
import Premium2 from '../images/Products/Product1/Premium2.png'
import Premium3 from '../images/Products/Product1/Premium3.png'
import Max1 from '../images/Products/Product1/Max1.png'
import Max2 from '../images/Products/Product1/Max2.png'
import Advance1 from '../images/Products/Product1/Advance1.png'
import Syntech1 from '../images/Products/Product1/Syntech1.png'
import Scootex1 from '../images/Products/Product1/Scootex1.png'
// 2ND
import EnergyM1 from '../images/Products/Car Oil 2/EnergyM1.png'
import EnergyM2 from '../images/Products/Car Oil 2/EnergyM2.png'
import EnergyM3 from '../images/Products/Car Oil 2/EnergyM3.png'
import GasEn1 from '../images/Products/Car Oil 2/GasEn1.png'
import GasEn2 from '../images/Products/Car Oil 2/GasEn2.png'
import GasEn3 from '../images/Products/Car Oil 2/GasEn3.png'
import Syntech11 from '../images/Products/Car Oil 2/Syntech1.png'
import SyntechSemi1 from '../images/Products/Car Oil 2/SyntechSemi1.png'
import Syntech5W40Full1 from '../images/Products/Car Oil 2/Syntech 5W40Full1.png'
import Syntech5W40Semi1 from '../images/Products/Car Oil 2/Syntech 5W40Semi1.png'
import Supreme1 from '../images/Products/Car Oil 2/Supreme1.png'
import Supreme2 from '../images/Products/Car Oil 2/Supreme2.png'


import PowerPackMain1 from '../images/Products/Car Oil 2/PowerPack4.png'
import PowerPack1 from '../images/Products/Car Oil 2/PowerPack1.png'
import PowerPack2 from '../images/Products/Car Oil 2/PowerPack2.png'
import PowerPack3 from '../images/Products/Car Oil 2/PowerPack3.png'
import PowerPackMain2 from '../images/Products/Car Oil 2/PowerPack4+.png'
import PowerPackPlus1 from '../images/Products/Car Oil 2/PowerPack+1.png'
import PowerPackPlus2 from '../images/Products/Car Oil 2/PowerPack4+.png'
import PowerPackPlus3 from '../images/Products/Car Oil 2/PowerPack4+.png'


import PowerPlusCH4 from '../images/Products/Car Oil 2/Power PlusCH 4.png'
import PowerPlusCH4_1 from '../images/Products/Car Oil 2/PowerPlusCH4_1.png'
import PowerPlusCF4 from '../images/Products/Car Oil 2/Power Plus CF.png'
import PowerPlusCF4_1 from '../images/Products/Car Oil 2/Power PlusCF_1.png'

// 3RD
import UTTO from '../images/Products/TractorEngine/UTTO.png'
import UTTO1 from '../images/Products/TractorEngine/UTTO1.png'
import UTTO2 from '../images/Products/TractorEngine/UTTO2.png'
import UTTO3 from '../images/Products/TractorEngine/UTTO3.png'
import SuperShakti from '../images/Products/TractorEngine/SuperShakti.png'
import SuperShakti1 from '../images/Products/TractorEngine/SuperShakti 1.png'
import TriboShakti from '../images/Products/TractorEngine/TriboShakti.png'
import TriboShakti1 from '../images/Products/TractorEngine/TriboShakti1.png'
//4 TH
import GearSaver90 from '../images/Products/GearCare/GearEP90.png'
import GearSaver901 from '../images/Products/GearCare/GearEP901.png'
import GearSaver902 from '../images/Products/GearCare/GearEP902.png'
import GearEP140 from '../images/Products/GearCare/GearEP140.png'
import GearEP1401 from '../images/Products/GearCare/GearEP140-1.png'
import GearEP1402 from '../images/Products/GearCare/GearEP140-2.png'
import Gear80W90 from '../images/Products/GearCare/Gear80W90.png'
import Gear80W901 from '../images/Products/GearCare/Gear80W90 - 1.png'
import Gear80W902 from '../images/Products/GearCare/Gear80W90 - 2.png'
import Gear85W140 from '../images/Products/GearCare/Gear85W140.png'
import Gear85W1401 from '../images/Products/GearCare/Gear85W140-1.png'
import Gear85W1402 from '../images/Products/GearCare/Gear85W140-2.png'
//5 TH
import Hydralic32 from '../images/Products/Hydraulic/32.png'
import Hydralic32_1 from '../images/Products/Hydraulic/32 - 1.png'
import Hydralic46 from '../images/Products/Hydraulic/46.png'
import Hydralic46_1 from '../images/Products/Hydraulic/46 - 1.png'
import Hydralic68 from '../images/Products/Hydraulic/68.png'
import Hydralic68_1 from '../images/Products/Hydraulic/68 - 1.png'
//6 TH
import ATF from '../images/Products/ATF,Break Oil/ATF.png'
import ATF1 from '../images/Products/ATF,Break Oil/ATF - 1.png'
import BreakOil from '../images/Products/ATF,Break Oil/Brake oil dot 3.png'
import BreakOil1 from '../images/Products/ATF,Break Oil/Brake oil dot 3 - 1.png'
import BreakOil4 from '../images/Products/ATF,Break Oil/Brake oil dot 4.png'
import BreakOil4_1 from '../images/Products/ATF,Break Oil/Brake oil dot 4 - 1.png'
import Fork from '../images/Products/ATF,Break Oil/Fork oil.png'
import Fork1 from '../images/Products/ATF,Break Oil/Fork oil - 1.png'
//7 TH
import HeavyDuty1 from '../images/Products/HaevyDuty/Radicool1.png'
import HeavyDuty2 from '../images/Products/HaevyDuty/Radicool2.png'
import HeavyDuty3 from '../images/Products/HaevyDuty/Radicool3.png'
//8 TH
import PumpSet from '../images/Products/PumpSet/PumpSet.png'
import PumpSet1 from '../images/Products/PumpSet/PumpSet-1.png'

import GasEngine from '../images/Products/Car Oil 2/Gas Engine Oil.png'
import EnergyMulti from '../images/Products/Car Oil 2/Energy Multi Grade.png'
import SynteFully from '../images/Products/Car Oil 2/Synte Fully Synthetic.png'
import SyntechSemi from '../images/Products/Car Oil 2/Syntech Semi Synthetic.png'
import Syntech5W40Fully from '../images/Products/Car Oil 2/Syntech Fully Synthetic.png'
import Syntech5W40Semi from '../images/Products/Car Oil 2/Syntech 5W40 Semi Synthetic.png'
import Supreme from '../images/Products/Car Oil 2/Supreme Plus 15W40.png'
export const productList = [
  {
    id: 1,
    name1: 'Two Wheeler ',
    name2: 'Engine Oil',
    text: "Revitalize your bike's performance with Trisol's premium lubricant engine oil. Engineered with precision and a relentless commitment to quality, our bike engine oil ensures peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we drive your ride to new heights of excellence. Experience the difference with Trisol and elevate your biking experience today.",
    allProducts: [{
      id: 1,
      image: Extrem,
      desc: 'Experience peak performance and unparalleled reliability with Trisol EXTREME 4T API SL 20W40. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Extreme 4T 20W40',
      title: 'Trisol EXTREME 4T API SL 20W40',
      size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
      productImage: [{ id: 11, image: Extr1 }, { id: 12, image: Extr3 }, { id: 13, image: Extr2 }],
      title1: 'API SL Certification: ',
      text1: "Meets API SL specifications, guaranteeing quality and compatibility with modern bike engines."

    },
    {
      id: 2,
      image: Ultra,
      desc: 'Experience peak performance and unparalleled reliability with Trisol Ultra 4T API SM 20W40. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Ultra 4T 20W40',
      title: 'Trisol Ultra 4T API SM 20W40',
      size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
      productImage: [{ id: 11, image: Ultra1 }, { id: 12, image: Ultra2 }, { id: 13, image: Ultra3 }],
      title1: 'API SM Certification: ',
      text1: "Meets API SM specifications, guaranteeing quality and compatibility with modern bike engines.",
    },
    {
      id: 3,
      image: Rider,
      desc: 'Experience peak performance and unparalleled reliability with Trisol Rider 4T API SM 20W40. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Rider 4T 20W40',
      size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
      title: 'Trisol Rider 4T API SM 20W40',
      productImage: [{ id: 11, image: Rider1 }, { id: 12, image: Rider2 }, { id: 13, image: Rider3 }],
      title1: 'API SM Certification: ',
      text1: "Meets API SM specifications, guaranteeing quality and compatibility with modern bike engines.",
    },
    {
      id: 4,
      image: Premium,
      desc: 'Experience peak performance and unparalleled reliability with Trisol Premium 4T API SN 20W40. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Premium 4T 20W40',
      size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
      title: 'Trisol Premium 4T API SN 20W40',
      productImage: [{ id: 11, image: Premium1 }, { id: 12, image: Premium2 }, { id: 13, image: Premium3 }],
      title1: 'API SN Certification: ',
      text1: "Meets API SN specifications, guaranteeing quality and compatibility with modern bike engines.",
    },
    {
      id: 5,
      image: Max,
      desc: 'Experience peak performance and unparalleled reliability with Trisol Max 4T API SN 20W50. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Max 4T 20W50',
      size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
      title: 'Trisol Max 4T API SN 20W50',
      productImage: [{ id: 11, image: Max1 }, { id: 12, image: Max2 }],
      title1: 'API SN Certification: ',
      text1: "Meets API SN specifications, guaranteeing quality and compatibility with modern bike engines.",
    },
    {
      id: 6,
      image: Advanced,
      desc: 'Experience peak performance and unparalleled reliability with Trisol Advance 4T API SM 10W30. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Advance 4T 10W30',
      size: '500 ml, 900 ml, 1 Ltr, 55 Ltr, 210 Ltr',
      title: 'Trisol Advance 4T API SM 10W30',
      productImage: [{ id: 11, image: Advance1 }],
      title1: 'API SM Certification: ',
      text1: "Meets API SM specifications, guaranteeing quality and compatibility with modern bike engines.",
    },
    {
      id: 7,
      image: Syntech,
      desc: 'Experience peak performance and unparalleled reliability with Trisol Syntech 4T API SN 5W30. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Syntech 4T 5W30',
      size: '500 ml, 900 ml, 1 Ltr, 55 Ltr, 210 Ltr',
      title: 'Trisol Syntech 4T API SN 5W30',
      productImage: [{ id: 11, image: Syntech1 }],
      title1: 'API SN Certification: ',
      text1: "Meets API SN specifications, guaranteeing quality and compatibility with modern bike engines.",
    },
    {
      id: 8,
      image: Scootex,
      desc: 'Experience peak performance and unparalleled reliability with Trisol Scootex 4T API SN 10W30. Elevate your ride with superior lubrication and endurance, trusted by bikers worldwide.',
      name: 'Scootex 4T 10W30',
      size: '800 ml, 900 ml, 1 Ltr, 55 Ltr, 210 Ltr',
      title: 'Trisol Scootex 4T API SN 10W30',
      productImage: [{ id: 11, image: Scootex1 }],
      title1: 'API SN Certification: ',
      text1: " Meets API SN specifications, guaranteeing quality and compatibility with modern bike engines.",
    },
    ]
  },
  {
    id: 2,
    name1: 'Car ',
    name2: 'Engine Oil',
    text: "Revitalize your car's performance with Trisol's premium lubricant engine oil. Engineered with precision and a relentless commitment to quality, our car engine oil ensures peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we drive your ride to new heights of excellence. Experience the difference with Trisol and elevate your driving experience today.",
    allProducts: [
      {
        id: 1,
        image: GasEngine,
        desc: 'Experience peak performance and unmatched reliability with Trisol Gas Engine Oil API SM 20W50. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Gas Engine Oil 20W50',
        title: 'Trisol gas Engine oil API Sm 20W50',
        size: '900 ml, 1 Ltr,3 Ltr, 3.5 Ltr, 10 Ltr,20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: GasEn1 }, { id: 12, image: GasEn2 }, { id: 13, image: GasEn3 }],
        title1: 'API SM Certification: ',
        text1: "Meets API SM specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 2,
        image: EnergyMulti,
        desc: 'Experience peak performance and unmatched reliability with Trisol Energy API CI-4 20W40. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Energy Multi Grade 20W40',
        title: 'Trisol energy API ci-4 20W40 multi-grade',
        size: '1 Ltr,3 Ltr, 3.5 Ltr, 10 Ltr,20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: EnergyM1 }, { id: 12, image: EnergyM2 }, { id: 13, image: EnergyM3 }],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 3,
        image: PowerPackMain1,
        desc: 'Experience peak performance and unmatched reliability with Trisol Power Pack API CI-4 15W40. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Power Pack API CI-4 15W40',
        title: 'Trisol Power pack API ci-4 15W40',
        size: '1 Ltr, 3 Ltr, 3.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: PowerPack1 }, { id: 12, image: PowerPack2 }, { id: 13, image: PowerPack3 }],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 4,
        image: PowerPackMain2,
        desc: 'Experience peak performance and unmatched reliability with Trisol Power Pack API CI-4+ 15W40. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Power Pack API CI-4+ 15W40',
        title: 'Trisol Power pack API ci-4+ 15W40',
        size: '1 Ltr, 3 Ltr, 3.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: PowerPackPlus1 }, { id: 12, image: PowerPackPlus2 }, { id: 13, image: PowerPackPlus3 }],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 5,
        image: PowerPlusCH4,
        desc: 'Experience peak performance and unmatched reliability with Trisol Power Plus API CI 4 15W40. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Power Plus API CH 4 15W40',
        title: 'Trisol Power Plus api ch 4 15W40',
        size: '7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: PowerPlusCH4_1 }],
        title1: 'API CH Certification: ',
        text1: "Meets API CH specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 6,
        image: PowerPlusCF4,
        desc: 'Experience peak performance and unmatched reliability with Trisol Power Plus API CF 4 15W40. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Power Plus API CF 4 15W40',
        title: 'Trisol Power Plus api cF 4 15W40',
        size: '7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: PowerPlusCF4_1 }],
        title1: 'API CF Certification: ',
        text1: "Meets API CF specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 7,
        image: SynteFully,
        desc: 'Experience peak performance and unmatched reliability with Trisol Syntech API CI 4 5W30 Fully Synthetic. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Syntech 5W30 Fully Synthetic',
        title: 'Trisol syntech API ci 4 5W30 fully synthetic',
        size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Syntech11 }],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 8,
        image: SyntechSemi,
        desc: 'Experience peak performance and unmatched reliability with Trisol Syntech API CI 4 5W30 Semi Synthetic. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Syntech 5W30 Semi Synthetic',
        title: 'Trisol syntech API ci 4 5W30 semi synthetic',
        size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: SyntechSemi1 }],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 9,
        image: Syntech5W40Fully,
        desc: 'Experience peak performance and unmatched reliability with Trisol Syntech API CI 4 5W40 Fully Synthetic. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Syntech 5W40 Fully Synthetic',
        title: 'Trisol syntech API ci 4 5W40 fully synthetic',
        size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Syntech5W40Full1 }],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",
      },
      {
        id: 10,
        image: Syntech5W40Semi,
        desc: 'Experience peak performance and unmatched reliability with Trisol Syntech API CI 4 5W30 Semi Synthetic. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Syntech 5W40 Semi Synthetic',
        title: 'Trisol syntech API ci 4 5W40 semi synthetic',
        size: '500 ml, 900 ml, 1 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Syntech5W40Semi1 }],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",

      },
      {
        id: 11,
        image: Supreme,
        desc: 'Experience peak performance and unmatched reliability with Trisol Supreme Plus 15W40 API CI 4. Elevate your drive with superior lubrication trusted by drivers worldwide.',
        name: 'Supreme Plus 15W40',
        title: 'Trisol supreme plus 15W40 API ci 4',
        size: '1 Ltr, 3 Ltr, 3.5 Ltr, 5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Supreme1 }, { id: 12, image: Supreme2 },],
        title1: 'API CI Certification: ',
        text1: "Meets API CI specifications, ensuring top-notch quality and compatibility with modern car engines.",

      },
    ]
  },
  {
    id: 3,
    name1: 'Tractor ',
    name2: 'Engine Oil',
    text: "Revitalize your tractor's performance with Trisol's premium lubricant engine oil. Engineered with precision and a relentless commitment to quality, our tractor engine oil ensures peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we drive your machinery to new heights of excellence. Experience the difference with Trisol and elevate your farming experience today.",
    allProducts: [
      {
        id: 1,
        image: UTTO,
        desc: 'Experience peak performance and unmatched reliability with Trisol Universal Tractor Transmission Oil (UTTO). Trusted by farmers worldwide, it offers superior lubrication for maximum productivity and prolonged engine life. Elevate your farming experience with Trisol.',
        name: 'UTTO',
        title: 'Trisol universal tractor transmission oil',
        size: '3 Ltr, 3.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: UTTO1 }, { id: 12, image: UTTO2 }, { id: 13, image: UTTO3 }],

      },
      {
        id: 2,
        image: SuperShakti,
        desc: 'Experience peak performance and unmatched reliability with Trisol Super Shakti 20W40 CF4. Trusted by farmers worldwide, it offers superior lubrication for maximum productivity and prolonged engine life. Elevate your farming experience with Trisol.',
        name: 'Super Shakti 20W40',
        title: 'Trisol super shakti 20W40 cf4',
        size: '7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: SuperShakti1 }],

      },
      {
        id: 3,
        image: TriboShakti,
        desc: 'Experience peak performance and unmatched reliability with Trisol Tribo Shakti 15W40 CI 4. Trusted by farmers worldwide, it offers superior lubrication for maximum productivity and prolonged engine life. Elevate your farming experience with Trisol.',
        name: 'Tribo Shakti 15W40',
        title: 'Trisol tribo shakti 15w40 ci 4',
        size: "7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr",
        productImage: [{ id: 11, image: TriboShakti1 }],

      },
    ]
  },
  {
    id: 4,
    name1: 'Gear ',
    name2: 'Care Oil',
    text: "Revitalize your gear's performance with Trisol's premium lubricant gear care oil. Engineered with precision and a relentless commitment to quality, our gear care oil ensures peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we optimize your machinery to new heights of excellence. Experience the difference with Trisol and elevate your gear maintenance experience today.",
    allProducts: [
      {
        id: 1,
        image: GearSaver90,
        desc: 'Experience peak performance and unmatched reliability with Trisol Gear Saver EP90 API GL-4. Trusted by professionals worldwide, it offers superior lubrication for smooth gear operation. Elevate your machinery\'s performance with Gear Saver EP90.',
        name: 'Gear Saver Ep 90',
        title: 'Trisol Gear Saver EP90 API GL-4',
        size: '1 Ltr, 5 Ltr, 7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: GearSaver901 }, { id: 12, image: GearSaver902 }],

      },
      {
        id: 2,
        image: GearEP140,
        desc: 'Experience peak performance and unmatched reliability with Trisol Gear Saver EP140 API GL-4. Trusted by professionals worldwide, it offers superior lubrication for smooth gear operation. Elevate your machinery\'s performance with Gear Saver EP140.',
        name: 'Gear Saver EP 140',
        title: 'Trisol Gear Saver EP140 API GL-4',
        size: '1 Ltr, 5 Ltr, 7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: GearEP1401 }, { id: 12, image: GearEP1402 }],

      },
      {
        id: 3,
        image: Gear80W90,
        desc: 'Experience peak performance and unmatched reliability with Trisol Gear Saver 80W90. Trusted by professionals worldwide, it offers superior lubrication for smooth gear operation. Elevate your machinery\'s performance with Gear Saver 80W90.',
        name: 'Gear Saver 80W90',
        title: 'Trisol gear Saver 80w90',
        size: '1 Ltr, 5 Ltr, 7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Gear80W901 }, { id: 12, image: Gear80W902 }],

      },
      {
        id: 4,
        image: Gear85W140,
        desc: 'Experience peak performance and unmatched reliability with Trisol Gear Saver 85W140. Trusted by professionals worldwide, it offers superior lubrication for smooth gear operation. Elevate your machinery\'s performance with Gear Saver 85W140.',
        name: 'Gear Saver 85W140',
        title: 'Trisol Gear Saver 85W140',
        size: '1 Ltr, 5 Ltr, 7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Gear85W1401 }, { id: 12, image: Gear85W1402 }],

      },
    ]
  },
  {
    id: 5,
    name1: 'Hydraulic ',
    name2: 'Oil',
    text: "Revitalize your hydraulic system's performance with Trisol's premium lubricant hydraulic oil. Engineered with precision and a relentless commitment to quality, our hydraulic oil ensures peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we optimize your machinery to new heights of excellence. Experience the difference with Trisol and elevate your hydraulic system maintenance experience today.",
    allProducts: [
      {
        id: 1,
        image: Hydralic32,
        desc: 'Experience peak performance and unmatched reliability with Trisol Hydraulic Oil 32. Trusted by professionals worldwide, it offers superior lubrication for efficient hydraulic system operation. Elevate your machinery\'s performance with Trisol Hydraulic Oil 32.',
        name: 'Hydraulic Oil 32',
        title: 'Trisol Hydraulic Oil 32',
        size: '1 Ltr, 5 Ltr, 10 Ltr, 26 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Hydralic32_1 }],

      },
      {
        id: 2,
        image: Hydralic46,
        desc: 'Experience peak performance and unmatched reliability with Trisol Hydraulic Oil 46. Trusted by professionals worldwide, it offers superior lubrication for efficient hydraulic system operation. Elevate your machinery\'s performance with Trisol Hydraulic Oil 46.',
        name: 'Hydraulic Oil 46',
        title: 'Trisol Hydraulic Oil 46',
        size: '1 Ltr, 5 Ltr, 10 Ltr, 26 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Hydralic46_1 }],

      },
      {
        id: 3,
        image: Hydralic68,
        desc: 'Experience peak performance and unmatched reliability with Trisol Hydraulic Oil 68. Trusted by professionals worldwide, it offers superior lubrication for efficient hydraulic system operation. Elevate your machinery\'s performance with Trisol Hydraulic Oil 68.',
        name: 'Hydraulic Oil 68',
        title: 'Trisol Hydraulic Oil 68',
        size: '1 Ltr, 5 Ltr, 10 Ltr, 26 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: Gear80W901 }, { id: 12, image: Gear80W902 }],

      },
      // {
      //   id: 4,
      //   image: Gear85W140,
      //   desc:'',
      //   name: 'Gear Saver 85W140',
      //   title: 'Trisol Gear Saver 85W140',
      //   size: '1 Ltr, 5 Ltr, 7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
      //   productImage: [{ id: 11, image: Hydralic68_1 }],

      // },
    ]
  },
  {
    id: 6,
    name1: 'Brake ',
    name2: 'Oil',
    name3: 'Fork',
    name4: 'Oil &',
    name5: 'Power Steering ',
    text: "Revitalize your hydraulic system's performance with Trisol's premium lubricant hydraulic oil, brake oil, front fork oil, and power steering oil. Engineered with precision and a relentless commitment to quality, our oils ensure peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we optimize your machinery and vehicle systems to new heights of excellence. Experience the difference with Trisol and elevate your maintenance experience today.",
    allProducts: [
      {
        id: 1,
        image: ATF,
        desc: 'Experience peak performance and unmatched reliability with Trisol Automatic Transmission Fluid (ATF). Trusted by professionals worldwide, it offers superior lubrication for smooth transmission operation. Elevate your vehicle\'s performance with Trisol ATF.',
        name: 'TQ (ATF)',
        title: 'Trisol Automatic transmission fluid (ATF)',
        size: '500 ml, 1 Ltr, 3 Ltr, 5 Ltr',
        productImage: [{ id: 11, image: ATF1 }],

      },
      {
        id: 2,
        image: BreakOil,
        desc: 'Experience peak performance and unmatched reliability with Trisol Brake Oil Dot 3. Trusted by professionals worldwide, it ensures optimal brake system operation. Elevate your vehicle\'s safety with Trisol Dot 3, the choice for dependable braking performance.',
        name: 'Brake Oil Dot 3',
        title: 'Trisol Brake Oil Dot 3',
        size: '175ml, 350 ml',
        productImage: [{ id: 11, image: BreakOil1 }],

      },
      {
        id: 3,
        image: BreakOil4,
        desc: 'Experience peak performance and unmatched reliability with Trisol Brake Oil Dot 4. Trusted by professionals worldwide, it ensures optimal brake system operation. Elevate your vehicle\'s safety with Trisol Dot 4, the choice for dependable braking performance.',
        name: 'Brake Oil Dot 4',
        title: 'Trisol Brake Oil Dot 4',
        size: '175ml, 350 ml',
        productImage: [{ id: 11, image: BreakOil4_1 }],

      },
      {
        id: 4,
        image: Fork,
        desc: 'Experience peak performance and unmatched reliability with Trisol Fork Oil - Shock Absorber Oil. Trusted by professionals worldwide, it ensures superior suspension system operation. Elevate your vehicle\'s handling with Trisol Fork Oil, the choice for smooth rides and enhanced control.',
        name: 'Fork Oil',
        title: 'Trisol Fork Oil - Shoker Absorber Oil',
        size: '100 ml, 250 ml, 500 ml',
        productImage: [{ id: 11, image: Fork1 }],

      },
    ]
  },
  {
    id: 7,
    name1: 'Brake ',
    name2: 'Oil',
    nam3: 'Fork',
    nam4: 'Power Steering ',
    nam5: 'Oil &',
    text: "Revitalize your hydraulic system's performance with Trisol's premium lubricant hydraulic oil, brake oil, front fork oil, and power steering oil. Engineered with precision and a relentless commitment to quality, our oils ensure peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we optimize your machinery and vehicle systems to new heights of excellence. Experience the difference with Trisol and elevate your maintenance experience today.",
    allProducts: [
      {
        id: 1,
        image: ATF,
        desc: 'Experience peak performance and unmatched reliability with Trisol Radicool Heavy Duty Coolant. Trusted by professionals worldwide, it offers superior cooling and corrosion protection for heavy-duty engines. Elevate your vehicle\'s performance with Trisol Radicool.',
        name: 'TQ (ATF)',
        title: 'Trisol Radicool Heavy Duty Coolant',
        size: '1 Ltr, 5 Ltr, 10 Ltr, 26 Ltr, 55 Ltr, 210 Ltr',
        productImage: [{ id: 11, image: HeavyDuty1 }, { id: 12, image: HeavyDuty2 }, { id: 13, image: HeavyDuty3 }],

      },

    ]
  },
  {
    id: 8,
    name1: 'Brake ',
    name2: 'Oil',
    nam3: 'Fork',
    nam4: 'Power Steering ',
    nam5: 'Oil &',
    text: "Revitalize your hydraulic system's performance with Trisol's premium lubricant hydraulic oil, brake oil, front fork oil, and power steering oil. Engineered with precision and a relentless commitment to quality, our oils ensure peak performance and unmatched reliability mile after mile. Trust in our expertise and dedication to innovation as we optimize your machinery and vehicle systems to new heights of excellence. Experience the difference with Trisol and elevate your maintenance experience today.",
    allProducts: [
      {
        id: 1,
        image: PumpSet,
        desc: 'Experience peak performance and unmatched reliability with Trisol Pump Set Oil. Trusted by professionals worldwide, it offers superior lubrication for efficient pump operation. Elevate your machinery\'s performance with Trisol Pump Set Oil.',
        name: 'TQ (ATF)',
        title: 'Trisol Pump Set Oil',
        size: '500 ml, 1 Ltr, 3 Ltr, 5 Ltr',
        productImage: [{ id: 11, image: PumpSet1 }],
      },

    ]
  },

]
import React from 'react'
import Image1 from '../images/Homepage/image 11.png'
import Image1MB from '../images/Homepage/image 12.png'
import InputComponent from './InputComponent'
import ButtonComp from './ButtonComp'
import { Email, Location, Phone } from '../images/icons'
import InputTextArea from './InputTextArea'

const CareerOppor = () => {
  return (
    <div className={`max-w-[1212px] m-auto flex flex-row-reverse max_500:flex-col mb-[100px] max_768:mb-[60px] h-full mt-[50px] max_500:mb-[50px]`} style={{
      boxShadow: ' 2px 2px 12px 0px #0000001A'
    }}>
      <div className='w-[54%] relative max_500:hidden'>
        <img src={Image1} alt='image' className='w-full h-full' />
      </div>
      <div className='hidden max_500:block h-[350px]'>
        <img src={Image1MB} alt='image' className='w-full h-full' />
      </div>
      <div className='flex flex-col py-[70px] px-[50px] w-full max_768:px-[30px] max_768:py-[30px] max_500:px-5'>
        <div className='grid grid-cols-2 gap-[21px] max_768:grid-cols-1 max_768:gap-[14px]'>

          <InputComponent placeholder="First Name" />
          <InputComponent placeholder="Your Email*" />
          <InputComponent placeholder="Phone Number*" />
          <InputComponent placeholder="Applying for which role" />
        </div>
        <div className='flex items-center mt-[21px] max_768:mt-[14px]'>
          <input className='w-full px-4 py-[11px] bg-[#F5F5F5] focus:outline-none Text18Sans text-gray rounded-l-[5px]' placeholder="Resume*" />
          <p className='bg-[#C8C8C8] px-[50px] py-[11px] max_980:px-[20px] rounded-r-[5px] Text18Sans cursor-pointer'>Browse</p>
        </div>
        <div className='mt-[21px]'>
          <InputTextArea placeholder="Your message" />
        </div>
        <div className='mt-[4%]'>

          <ButtonComp text="Submit" left="65px" />
        </div>
      </div>
    </div>
  )
}

export default CareerOppor

import React, { useEffect, useRef } from 'react'
import AboutImg from '../images/About/AboutImg.png'
import ButtonComp from './ButtonComp'
import HeadingComp from './HeadingComp'



const AboutSection = () => {


  return (
    <div className='relative w-full max-w-[1920px] m-auto bg-Light_white h-[700px] max_980:h-auto'>
      <div className='max-w-[1460px] m-auto flex items-center h-full max_xl1460:max-w-[1024px] max_980:max-w-auto max_980:py-[50px] max_980:px-10 max_500:px-5 max_500:py-[40px]'>
        <div className='h-full w-[45%] flex flex-col gap-[30px] justify-center max_980:w-full'>
          <HeadingComp head1='About' head2='“TRISOL”' />

          <p className='Text18Sans' data-aos="fade-right">Welcome to Trisol, your trusted partner in lubricants and automobile oils.</p>
          <p className='Text18Sans max_xl1460:text-[16px]' data-aos="fade-right">Trisol is a leading manufacturer and supplier of high-quality lubricants and oils, dedicated to providing superior products and unmatched service to our customers worldwide. With a commitment to innovation, excellence, and sustainability, we strive to meet the evolving needs of the automotive industry while exceeding expectations in performance and reliability.</p>
          <p className='Text18Sans max_xl1460:text-[16px]' data-aos="fade-left">At Trisol, we understand the critical role lubricants play in the efficient operation and longevity of vehicles and machinery. That's why we leverage cutting-edge technology and extensive research to develop formulations that deliver optimal lubrication, enhance engine performance, and prolong equipment life. Our comprehensive product range covers a wide spectrum of applications, including engine oils, transmission fluids, hydraulic oils, and more, ensuring that we have the right solution for every requirement.</p>
          <ButtonComp text="Read more" />
        </div>

      </div>
      <div className='hidden max_980:block'>
        <img src={AboutImg} alt='About_img' className='h-[100%] w-[100%]' />
      </div>
      <div className='absolute right-0 top-0 w-[50%] h-[100%] max_980:hidden'>
        <img src={AboutImg} alt='About_img' className='h-[100%] w-[100%]' />
      </div>
      {/* <div className='flex fle'></div> */}
    </div>
  )
}

export default AboutSection

import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useParams, useNavigate } from 'react-router-dom';
import ProductCard from '../Components/ProductCard'
import HeadingComp from '../Components/HeadingComp';
import { productList } from '../Components/Data';
import ButtonComp from '../Components/ButtonComp';
import { ThreeDots } from 'react-loader-spinner';
import ButtonComp1 from '../Components/ButtonComp1';

const ProductPage = () => {
  const { id1 } = useParams();
  const navigate = useNavigate()
  const findProduct = productList.find(product => product.id == id1);

  const [selectedProducts, setSelectedProducts] = useState(findProduct)
  const handleClick = (idx) => {
    navigate(`/products/${id1}/${idx}`)
  }


  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    if (selectedProducts) {
      const loadImage = (imageSrc) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = imageSrc;
          image.onload = resolve;
          image.onerror = reject;
        });
      };

      const loadAllImages = async () => {
        try {
          await Promise.all(selectedProducts?.allProducts?.map(item => loadImage(item.image)));
          setImagesLoaded(true);
        } catch (error) {
          console.error('Error loading images:', error);
        }
      };

      loadAllImages();
    }

  }, [selectedProducts]);

  return (
    <>
      <Navbar />
      {!imagesLoaded ? (<>
        <div className='w-full h-[600px] F_JC_IC'>
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="#004E72"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div></>) : (<>

          <div className='max-w-[1460px] m-auto flex flex-col items-center mt-[100px] max_768:mt-[60px] max_500:mt-[50px] gap-[50px] max_768:gap-[25px] max_500:gap-5 max_xl1460:max-w-[980px] max_980:max-w-[768px] max_768:px-10 max_500:px-5'>
            <div className='text-center'>
              <HeadingComp head1={selectedProducts?.name1} head2={selectedProducts?.name2} />
              <p className='Text18Sans mt-[30px] text-center'>{selectedProducts?.text}</p>
            </div>

            <div className='grid grid-cols-3 mb-[100px] max_xl1460:grid-cols-2 max_xl1460:mb-[70px] max_500:mb-[50px] gap-y-[50px]'>
              {selectedProducts?.allProducts.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <ProductCard item={item} handleClick={handleClick} id={index} />
                  </React.Fragment>
                )
              })}

            </div>

            <div className='maxButton mb-[100px]'>
              <ButtonComp1 text="Back to Products" handleOnClick={() => navigate('/products')} />
            </div>
          </div>
        </>)}

      <Footer />
    </>
  )
}

export default ProductPage

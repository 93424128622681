import React, { useState } from 'react'
import Amazon from '../images/Homepage/Amazon.png'
import Flipkart from '../images/Homepage/Flipkart.png'
import Jiomart from '../images/Homepage/Jio mart.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules';

const Available = () => {
  const [imageData] = useState([
    { image1: Amazon },
    { image1: Amazon },
    { image1: Jiomart },
  ])
  const [storeHoverIndex,setStoreHoverIndex] = useState(null)
  return (
    <div className='bg-Light_white py-[100px] max_768:py-[60px] max_500:py-[48px]'>
      <div className='max-w-[1460px] m-auto max_xl1460:max-w-[1024px] max_1024:px-10 max_500:px-5'>
        <p className='Text36Monts text-Primary text-center'>We are  <span className='text-Secondary_Primary'>Available on</span></p>
        <div className='flex justify-center gap-5 mt-[40px] max_500:hidden'>
          {imageData.map((item, index) => {
            return (

              <div className={`py-[60px] px-[70px] max_768:py-[46px] max_768:px-[56px] border-2 bg-white max_720:py-[36px] max_720:px-[36px] ${storeHoverIndex === index ? 'border-[#E0E0E0]' :'border-white'}`} key={index} onMouseEnter={()=>setStoreHoverIndex(index)} onMouseLeave={()=>setStoreHoverIndex(null)}>
                <img src={item.image1} alt="amazon" className={`${storeHoverIndex === index ? 'scale-100':'scale-90'}`} />
              </div>
            )
          })}

        </div>


        <div className='hidden max_500:block'>
          <div className='flex justify-center px-10 max_500:px-5 py-[50px] max_500:py-[20px]'>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiperInfo"
              loop={true}
            >
              {imageData.map((data, idx) => (
                <SwiperSlide key={idx} style={{ background: "transparent" }}>
                  <div className='py-[60px] px-[70px] max_768:py-[46px] max_768:px-[56px] bg-white flex justify-center' >
                    <img src={data.image1} alt="amazon"  />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Available

import React, { useState } from 'react'
import HeadingComp from './HeadingComp'
import GoalImg from '../images/Homepage/TrisolGoal.png'
import Im1 from '../images/Gallary/1.png'
import Im2 from '../images/Gallary/2.png'
import Im3 from '../images/Gallary/3.png'
import Im4 from '../images/Gallary/4.png'
import Im5 from '../images/Gallary/5.png'
import Im6 from '../images/Gallary/11.png'
import Im7 from '../images/Gallary/12.png'
import Im8 from '../images/Gallary/13.png'
import Im9 from '../images/Gallary/14.png'
import Im10 from '../images/Gallary/15.png'
import Im11 from '../images/Gallary/16.png'
import Im12 from '../images/Gallary/17.png'
import Im13 from '../images/Gallary/18.png'
import ButtonComp from './ButtonComp'


const GallaryBanner = () => {
  const [imageArr, setImageArr] = useState([
    {
      image: Im6
    },
    {
      image: Im7
    },
    {
      image: Im8
    },
    {
      image: Im9
    },
    {
      image: Im10
    },
    {
      image: Im11
    },
    {
      image: Im12
    },
    {
      image: Im13
    },
  ])
  return (
    <div className='max-w-[1460px] m-auto flex flex-col items-center mt-[100px] max_768:mt-[60px] max_500:mt-[50px] gap-[100px] max_768:gap-16 max_500:gap-12 max_xl1460:max-w-[980px] max_980:max-w-[768px] max_768:px-10 max_500:px-5'>
      <div className='text-center'>
        <HeadingComp head1="Discover Our" head2="Gallery" />
        <p className='Text18Sans mt-[30px] text-center'>Step into our gallery and immerse yourself in a visual journey showcasing the excellence of Trisol Lubricant Engine Oil. Explore the precision engineering and innovation behind each product, captured in stunning detail. Let our gallery inspire you to experience peak performance and reliability like never before.</p>
      </div>
      <p className='w-[500px] h-[1px] bg-Primary'></p>


      <div className='flex justify-between items-center max_768:flex-col max_768:gap-5'>
        <div className='w-[48%] max_768:w-[100%]' data-aos="zoom-in">
          <img src={GoalImg} alt='goal_img' />
        </div>
        <div className='flex flex-col gap-[30px] w-[48%] max_768:w-[100%]' data-aos="fade-left">
          <p className='Text36Monts text-Primary'>Our  <span className='text-Secondary_Primary'>Brochure</span></p>
          <p className='Text18Sans'>Dive into our brochure to explore the advanced formulations and cutting-edge technology powering Trisol Lubricant Engine Oil. Elevate your vehicle's performance with precision engineering and unparalleled reliability.</p>
          <ButtonComp text="Download" />
        </div>
      </div>

      <div className='grid grid-cols-2 gap-[30px] max_768:grid-cols-1'>
        <div>
          <img src={Im1} alt='1st_img' />
        </div>
        <div>
          <img src={Im2} alt='2st_img' />
        </div>
      </div>
      <div className='grid grid-cols-3 gap-[32px] w-full max_768:grid-cols-1 place-items-center'>
        <div>
          <img src={Im3} alt='3st_img' className='' />
        </div>
        <div>
          <img src={Im4} alt='4st_img' className='' />
        </div>
        <div>
          <img src={Im5} alt='4st_img' className='' />
        </div>
      </div>

      <div className='grid grid-cols-2 gap-[30px] mb-[200px] max_768:grid-cols-1 max_768:mb-[100px] max_500:mb-[50px]'>
        {imageArr.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div>
                <img src={item.image} alt='1st_img' />
              </div>
            </React.Fragment>
          )
        })}

      </div>
    </div>
  )
}

export default GallaryBanner

import React, { useEffect, useState } from 'react'
import TrisolLogo from '../images/Navbar/Logo.svg'
import { ReactComponent as SearchIcon } from '../images/Navbar/SearchIcon.svg'
import {  useLocation, useNavigate } from 'react-router-dom'
import Menu from '../images/Navbar/Menu.svg'
import CloseIcon from '../images/Navbar/CloseIcon.svg'

const Navbar = () => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(1)
  const [navbarList, setNavbarList] = useState([
    {
      id: 1,
      name: 'Home',
      link: '/',
      active: true
    },
    {
      id: 2,
      name: 'Products',
      link: '/products',
      active: false
    },
    {
      id: 3,
      name: 'Gallery',
      link: '/gallary',
      active: false
    },
    {
      id: 4,
      name: 'About Us',
      link: '/about-us',
      active: false
    },
    {
      id: 5,
      name: 'Career',
      link: '/career',
      active: false
    },
    {
      id: 6,
      name: 'Contact Us',
      link: '/contact-us',
      active: false
    },
  ])
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const location = useLocation()

  useEffect(() => {
    setIsOpenMenu(false)
  }, [location])

  return (
    <div className='m-auto' style={{
      boxShadow: '0px 2px 4px 0px #0000001A'
    }}>

      <div className='relative w-full max-w-[1460px] m-auto flex justify-between h-[76px] max_500:h-[64px] items-center max_xl1460:max-w-[1024px] max_xl1460:gap-4 max_1024:px-6 max_1024:justify-center'>

        {/* HAMBURGER */}
        <div className='hidden max_1024:block'>
          <div className='absolute left-6 top-6 cursor-pointer' onClick={()=>setIsOpenMenu(!isOpenMenu)}>
            <img src={Menu} alt='Menu' />
          </div>
        </div>

        {/* LOGO */}
        <div className='max_xl1460:w-[210px] cursor-pointer max_500:w-[170px]' onClick={()=>navigate('/')}>
          {/* <TrisolLogo /> */}
          <img src={TrisolLogo} alt='Logo' className='w-[100%]' />
        </div>

        {/* WEB SEARCH AND MENU */}

        <div className='flex gap-16 max_xl1460:gap-2 max_1024:hidden'>

          <div className='flex gap-4 items-center justify-between max_xl1460:gap-1'>
            {navbarList.map((list, index) => {
              return (
                <React.Fragment key={index}>

                  <div className={`relative font-OpenSansF font-semibold text-[18px] max_xl1460:text-[16px] w-[110px] max_xl1460:w-[94px] max-w-[100px]  text-center cursor-pointer ${location.pathname === list.link ? 'text-Secondary_Primary' : 'text-Primary'}`} onClick={() => {
                    navigate(list.link)
                    // handleClick(index,list.link)
                  }}>{list.name}
                    {location.pathname === list.link && (<>
                      <p style={{ clipPath: 'polygon(53% 0, 0 100%, 100% 100%)' }} className='absolute bg-Secondary_Primary h-[6px] w-[20px] top-[44px] left-[44%]'></p>
                      <p className='absolute w-full h-[4px] bg-Secondary_Primary top-[47px]'></p>
                    </>)}
                  </div>
                </React.Fragment>
              )
            })}

          </div>
          <div className='w-[240px] max_xl1460:w-[210px] flex'>
            <input className='w-[200px] max_xl1460:w-[170px] outline-none border-none rounded-l-[5px] bg-light_White h-10 text-[18px] font-normal font-OpenSansF px-4 py-[7px]' placeholder='Search' />
            <div className='w-[40px] h-[40px] rounded-[5px] bg-Primary flex justify-center items-center'>
              <SearchIcon />
            </div>
          </div>
        </div>

        {/* MOBILE SEARCH */}
        <div className='hidden max_1024:block max_768:hidden'>
          <div className='absolute right-6 top-4'>
            <div className='w-[240px] max_xl1460:w-[210px] flex'>
              <input className='w-[200px] max_xl1460:w-[170px] outline-none border-none rounded-l-[5px] bg-light_White h-10 text-[18px] font-normal font-OpenSansF px-4 py-[7px]' placeholder='Search' />
              <div className='w-[40px] h-[40px] rounded-[5px] bg-Primary flex justify-center items-center'>
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>

        {isOpenMenu && (
            <div className='absolute w-full left-0 top-0 bg-white py-[8px] z-50 flex flex-col'>
              <div className='mx-[60px] my-5 w-max cursor-pointer' onClick={()=>setIsOpenMenu(false)}>
                <img src={CloseIcon} alt='close' />
              </div>
              {navbarList.map((list, index) => {
              return (
                <React.Fragment key={index}>

                  <div className={`relative font-OpenSansF font-semibold text-[18px] py-3 pl-[60px] cursor-pointer  border-l-[4px] border-t-[1px] border-t-Light_border ${location.pathname === list.link ? 'text-Secondary_Primary border-Secondary_Primary' : 'text-Primary border-white'}`} onClick={() => {
                    navigate(list.link)
                    // handleClick(index,list.link)
                  }}>{list.name}
                    
                  </div>
                </React.Fragment>
              )
            })}
            </div>
          )}
      </div>
    </div>
  )
}

export default Navbar


// ${list.active === true ? 'text-Secondary_Primary' : 'text-Primary'}
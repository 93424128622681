import React from 'react'
import HeadingComp from '../Components/HeadingComp'
import GetInTouch from '../Components/GetInTouch'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import MapChart from '../Components/MapChart'

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <div>
        <div className='w-full max-w-[1212px] max_xl1460:max-w-[980px] max_1024:px-10 max_500:px-5 m-auto my-[100px] max_500:my-[50px] max_768:my-[60px] text-center'>
          <HeadingComp head1="Trisol :" head2="Lubricants Across Continents" />
          <p className='Text18Sans mt-[30px]'>Providing Industry-Leading Lubricant Solutions Tailored to Enhance Performance, Efficiency, and Reliability for Businesses and Industries Across Continents</p>

        </div>
        <div className='max-w-[1380px] max_xl1460:max-w-[980px] max_980:px-10 max_500:px-5 m-auto mt-[50px] max_500:mt-[30px]'>
          <MapChart />
        </div>
      </div>
      <div className='w-full max-w-[1212px] max_xl1460:max-w-[980px] max_1024:px-10 max_500:px-5 m-auto my-[100px] max_500:my-[50px] max_768:my-[60px] text-center'>
        <HeadingComp head1="Feel Free to" head2="Contact Us" />
        <p className='Text18Sans mt-[30px]'>Get in Touch with Trisol: Your Gateway to Exceptional Service! Have questions, feedback, or inquiries? We're here to listen and assist you every step of the way. Reach out today and experience our unparalleled commitment to customer satisfaction. Let's connect and pave the way for success together!</p>

      </div>
      {/* <div className='w-full max-w-[1212px] max_xl1460:max-w-[980px] max_1024:px-10 max_500:px-5 m-auto'> */}

      <GetInTouch marginTop={true} />
      {/* </div> */}

      <Footer />
    </>

  )
}

export default ContactUs

import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { productList } from './Data'
import ServiceComp from './ServiceComp'
import ButtonComp from './ButtonComp'
import { ThreeDots } from 'react-loader-spinner'

const SingleProdComp = () => {
  const { id1, id2 } = useParams()
  const location = useLocation()
  const [productData, setProductData] = useState([])
  const [selectedImage, setSelectedImage] = useState()
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [RecData1] = useState([
    'All types of modern motorcycles, including commuter bikes, cars, tractors and heavy duty coolants.',
    'Riders seeking reliable engine protection and optimal performance in varied riding conditions.',
    'Those prioritizing quality and longevity for their bike\'s engine.'
  ])
  const [RecData2] = useState([
    'Tailored for modern agricultural machinery, our high-performance gear oil is the ideal choice for farmers seeking exceptional gear protection and top-notch performance across demanding farming conditions. Whether you\'re maneuvering heavy loads or navigating rough terrain, our product ensures reliability and longevity for your machinery\'s gears. Trust in our quality formulation to deliver optimal performance and safeguard your equipment for seasons to come.'
  ])
  const [RecData3] = useState([
    'Tailored for modern agricultural machinery, our high-performance tractor engine oil is the ideal choice for farmers seeking exceptional engine protection and top-notch performance across demanding farming conditions. Whether you\'re plowing fields or harvesting crops, our product ensures reliability and longevity for your tractor\'s engine. Trust in our quality formulation to deliver optimal performance and safeguard your equipment for seasons to come.'
  ])
  const [RecData4] = useState([
    'Tailored for modern agricultural machinery, our high-performance gear oil is the ideal choice for farmers seeking exceptional gear protection and top-notch performance across demanding farming conditions. Whether you\'re maneuvering heavy loads or navigating rough terrain, our product ensures reliability and longevity for your machinery\'s gears. Trust in our quality formulation to deliver optimal performance and safeguard your equipment for seasons to come.'
  ])
  const [RecData5] = useState([
    'Tailored for modern agricultural machinery, our high-performance hydraulic oil is the ideal choice for farmers seeking exceptional hydraulic system protection and top-notch performance across demanding farming conditions. Whether you\'re operating heavy machinery or handling precision tasks, our product ensures reliability and longevity for your hydraulic systems. Trust in our quality formulation to deliver optimal performance and safeguard your equipment for seasons to come.'
  ])
  const [RecData6] = useState([
    'Tailored for modern vehicles, our high-performance automatic transmission fluid (ATF) is the ideal choice for drivers seeking exceptional transmission protection and top-notch performance across varied driving conditions. Whether you\'re commuting in urban traffic or cruising on the highway, our product ensures reliability and longevity for your vehicle\'s transmission. Trust in our quality formulation to deliver optimal performance and safeguard your vehicle for miles to come.'
  ])
  const [RecData10] = useState([
    'Tailored for modern vehicles, our high-performance brake fluid, Trisol Brake Oil Dot 3, is the ideal choice for drivers seeking exceptional braking performance and safety across varied driving conditions. Whether you\'re navigating city streets or cruising on the open road, our product ensures reliable stopping power and longevity for your vehicle\'s braking system. Trust in our quality formulation to deliver optimal performance and safeguard your vehicle and passengers.'
  ])
  const [RecData11] = useState([
    'Tailored for modern vehicles, our high-performance brake fluid, Trisol Brake Oil Dot 4, is the ideal choice for drivers seeking exceptional braking performance and safety across varied driving conditions. Whether you\'re navigating city streets or cruising on the open road, our product ensures reliable stopping power and longevity for your vehicle\'s braking system. Trust in our quality formulation to deliver optimal performance and safeguard your vehicle and passengers.'
  ])
  const [RecData12] = useState([
    'Tailored for modern motorcycles and suspension systems, our high-performance fork oil, Trisol Fork Oil, is the ideal choice for riders seeking exceptional shock absorption and top-notch performance across varied road conditions. Whether you\'re cruising through city streets or tackling rugged terrain, our product ensures smooth handling and stability for your motorcycle\'s suspension. Trust in our quality formulation to deliver optimal performance and enhance your riding experience for miles to come.'
  ])
  const [RecData7] = useState([
    'Tailored for heavy-duty engines, our high-performance coolant, Trisol Radicool Heavy Duty Coolant, is the ideal choice for professionals seeking exceptional engine cooling and corrosion protection across demanding conditions. Whether you\'re operating heavy machinery or navigating rough terrain, our product ensures efficient heat dissipation and prolonged engine lifespan. Trust in our quality formulation to deliver optimal performance and safeguard your equipment\'s reliability and durability.'
  ])
  const [RecData8] = useState([
    'Tailored for industrial machinery, our high-performance pump set oil, Trisol Pump Set Oil, is the ideal choice for professionals seeking exceptional lubrication and efficiency across demanding operations. Whether you\'re powering irrigation systems or hydraulic equipment, our product ensures smooth and reliable pump operation. Trust in our quality formulation to deliver optimal performance and safeguard your machinery\'s longevity and productivity.'
  ])
  const [bebefitsData1] = useState([
    {
      title1: 'Optimal Performance: ',
      text1: "Formulated to ensure peak performance of your bike's engine under extreme conditions."
    },
    {
      title1: 'Superior Lubrication: ',
      text1: "Provides superior lubrication, reducing friction and wear for enhanced engine longevity."
    },
    {
      title1: 'Enhanced Endurance: ',
      text1: "Withstands high temperatures and stress, ensuring your engine's endurance over long rides."
    },

    {
      title1: 'Fuel Efficiency: ',
      text1: "Engineered to optimize fuel efficiency, saving you money on fuel costs."
    },
    {
      title1: 'Cleaner Engine: ',
      text1: "Helps maintain a cleaner engine by preventing sludge and deposits buildup."
    },
  ])
  const [bebefitsData2] = useState([
    {
      title1: 'Maximized Performance: ',
      text1: "Engineered to ensure your car's engine operates at peak performance even under extreme conditions."
    },
    {
      title1: 'Superior Lubrication: ',
      text1: "Provides exceptional lubrication, reducing friction and minimizing wear to extend engine lifespan."
    },
    {
      title1: 'Enhanced Endurance: ',
      text1: "Withstands high temperatures and stress, ensuring your engine's endurance over extended drives."
    },

    {
      title1: 'Improved Fuel Efficiency: ',
      text1: " Engineered to optimize fuel efficiency, saving you money on fuel expenses over time."
    },
    {
      title1: 'Cleaner Engine: ',
      text1: "Helps maintain a cleaner engine by preventing the buildup of sludge and deposits, promoting smoother operation and longevity."
    },
  ])
  const [bebefitsData3] = useState([
    {
      title1: 'Maximized Performance: ',
      text1: "Engineered to ensure your tractor's engine operates at peak performance even under rigorous conditions."
    },
    {
      title1: 'Superior Lubrication: ',
      text1: "Provides exceptional lubrication, reducing friction and minimizing wear to extend the engine's lifespan."
    },
    {
      title1: 'Enhanced Endurance: ',
      text1: "Withstands high temperatures and stress, ensuring your engine's endurance over long hours of operation."
    },

    {
      title1: 'Improved Fuel Efficiency: ',
      text1: "Engineered to optimize fuel efficiency, saving you money on fuel expenses over time."
    },
    {
      title1: 'Cleaner Engine: ',
      text1: "Helps maintain a cleaner engine by preventing the build-up of sludge and deposits, promoting smoother operation and longevity in the field."
    },
  ])
  const [bebefitsData4] = useState([
    {
      title1: 'Maximized Performance: ',
      text1: "Engineered to ensure your machinery's gears operate at peak performance even under rigorous conditions."
    },
    {
      title1: 'Superior Lubrication: ',
      text1: "Provides exceptional lubrication, reducing friction and minimizing wear to extend gear lifespan."
    },
    {
      title1: 'Enhanced Endurance: ',
      text1: "Withstands high temperatures and stress, ensuring your equipment's endurance over long hours of operation."
    },

    {
      title1: 'Improved Efficiency: ',
      text1: "Engineered to optimize gear efficiency, saving you money on maintenance expenses over time."
    },
    {
      title1: 'Cleaner Gears: ',
      text1: "Helps maintain cleaner gears by preventing the build-up of debris, promoting smoother operation and longevity in the field."
    },
  ])
  const [bebefitsData5] = useState([
    {
      title1: 'Maximized Performance: ',
      text1: "Engineered to ensure your hydraulic systems operate at peak performance even under rigorous conditions."
    },
    {
      title1: 'Superior Lubrication: ',
      text1: "Provides exceptional lubrication, reducing friction and minimizing wear to extend equipment lifespan."
    },
    {
      title1: 'Enhanced Endurance: ',
      text1: "Withstands high pressures and stress, ensuring your hydraulic system's endurance over long hours of operation."
    },

    {
      title1: 'Improved Efficiency: ',
      text1: "Engineered to optimize hydraulic system efficiency, saving you money on maintenance expenses over time."
    },
    {
      title1: 'Cleaner Systems: ',
      text1: "Helps maintain cleaner hydraulic systems by preventing the build-up of contaminants, promoting smoother operation and longevity in the field."
    },
  ])
  const [bebefitsData6] = useState([
    {
      title1: 'Maximized Performance: ',
      text1: "Engineered to ensure your vehicle's transmission operates at peak performance even under rigorous conditions."
    },
    {
      title1: 'Superior Lubrication: ',
      text1: "Provides exceptional lubrication, reducing friction and minimizing wear to extend transmission lifespan."
    },
    {
      title1: 'Enhanced Endurance: ',
      text1: "Withstands high temperatures and stress, ensuring your transmission's endurance over long hours of operation."
    },

    {
      title1: 'Improved Efficiency: ',
      text1: "Engineered to optimize transmission efficiency, saving you money on maintenance expenses over time."
    },
    {
      title1: 'Cleaner Systems: ',
      text1: "Helps maintain cleaner transmission systems by preventing the build-up of contaminants, promoting smoother shifts and longevity on the road."
    },
  ])
  const [bebefitsData7] = useState([
    {
      title1: 'Maximized Performance: ',
      text1: "Engineered to ensure your heavy-duty engine operates at peak performance even under demanding conditions."
    },
    {
      title1: 'Superior Cooling: ',
      text1: "Provides exceptional heat dissipation, ensuring optimal engine temperature regulation."
    },
    {
      title1: 'Enhanced Protection: ',
      text1: "Withstands extreme temperatures and corrosion, ensuring your engine's longevity over extended periods."
    },

    {
      title1: 'Improved Efficiency: ',
      text1: "Engineered to optimize coolant circulation, saving you money on maintenance expenses over time."
    },
    {
      title1: '',
      text1: "Trusted by professionals worldwide, elevate your vehicle's performance with Trisol Radicool Heavy Duty Coolant."
    },
  ])
  const [bebefitsData8] = useState([
    {
      title1: 'Maximized Performance: ',
      text1: "Engineered to ensure your vehicle's transmission operates at peak performance even under rigorous conditions."
    },
    {
      title1: 'Superior Lubrication: ',
      text1: "Provides exceptional lubrication, reducing friction and minimizing wear to extend transmission lifespan."
    },
    {
      title1: 'Enhanced Endurance: ',
      text1: "Withstands high temperatures and stress, ensuring your transmission's endurance over long hours of operation."
    },

    {
      title1: 'Improved Efficiency: ',
      text1: "Engineered to optimize transmission efficiency, saving you money on maintenance expenses over time."
    },
    {
      title1: 'Cleaner Systems: ',
      text1: "Helps maintain cleaner transmission systems by preventing the build-up of contaminants, promoting smoother shifts and longevity on the road."
    },
  ])
  useEffect(() => {
    const findProduct = productList.find(product => product.id == id1);
    const findInnerProd = findProduct.allProducts.find(innerProd => innerProd.id == id2)
    setProductData(findInnerProd)
  }, [id2])
  useEffect(() => {
    if (productData && productData.productImage) {
      setSelectedImage(productData.productImage[0])
    }
  }, [productData])

  console.log("product000", productData);
  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

  return (
    <>
      {!imagesLoaded && (<div className='w-full h-screen F_JC_IC'>
        <ThreeDots
          visible={true}
          height="100"
          width="100"
          color="#004E72"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>)}
      <div className={` max-w-[1460px] m-auto mt-[100px] max_768:mt-[60px] max_500:mt-[50px] max_xl1460:max-w-[980px] max_980:max-w-[768px] max_768:px-10 max_500:px-5 mb-[100px] ${imagesLoaded ? 'block' : 'hidden'}`}>
        <div className='flex flex-col gap-[100px]'>

          <div className='flex gap-[30px] justify-between max_980:flex-col'>
            <div className='w-[590px] max_xl1460:w-[470px] max_980:w-full'>
              <img src={selectedImage?.image} alt='image' className='w-[100%]' onLoad={handleImageLoad} />
              <div className='mt-5 flex gap-6'>
                {productData?.productImage?.map((item, index) => (
                  <div className={`w-[30%] rounded-[6px] cursor-pointer ${selectedImage?.id === item.id ? 'border-[1.5px] border-[#E2E2E2]' : ''}`} key={index} onClick={() => setSelectedImage(item)}>
                    <img src={item.image} alt='' className='rounded-[6px]' />
                  </div>
                ))}

              </div>
            </div>
            <div className='flex flex-col gap-10 w-[calc(100%-590px)] max_980:w-full max_xl1460:w-[calc(100%-470px)] max_xl1460:gap-7'>
              <div>
                <p className='text-[30px] font-medium text-Primary font-MontserratF max_xl1460:text-[29px] uppercase'>{productData?.title}</p>
                <p className='Text18Sans mt-5'>{productData?.desc}</p>
              </div>
              {/* LINE */}
              <div className='h-[1px] w-full bg-Primary'>
              </div>
              <ServiceComp />
              {/* LINE */}
              <div className='h-[1px] w-full bg-Primary'>
              </div>
              <div>
                <p className='text-[22px] font-MontserratF font-medium text-Primary max_980:text-[20px] max_500:text-[18px]'>Available Size</p>
                <p className='Text18Sans'>{productData?.size}</p>
              </div>
              {/* LINE */}
              <div className='h-[1px] w-full bg-Primary'></div>

              <div>
                <ButtonComp text="Call Us" />
              </div>
            </div>
          </div>
          <div className=''>
            <div className='flex max_500:flex-col'>
              <p className={`relative text-[20px] max_500:text-[16px] font-MontserratF font-medium w-[50%] max_500:w-full py-5 text-center   cursor-pointer ${selectedIndex === 1 ? 'border-b-2 border-Primary text-Primary' : 'border-b-[1px] border-[#99B8C7] text-[#99B8C7]'}`} onClick={() => setSelectedIndex(1)}>Benefits
                {selectedIndex === 1 && (
                  <>
                    <p style={{ clipPath: 'polygon(53% 0, 0 100%, 100% 100%)' }} className='absolute bg-Primary h-[6px] w-[20px] bottom-0 left-[49%]'></p>

                  </>
                )}
              </p>
              <p className={`relative text-[20px] max_500:text-[16px] font-MontserratF font-medium w-[50%] max_500:w-full py-5 text-center  cursor-pointer ${selectedIndex === 2 ? 'border-b-2 border-Primary text-Primary' : 'border-b-[1px] border-[#99B8C7] text-[#99B8C7]'}`} onClick={() => setSelectedIndex(2)}>Recommendation
                {selectedIndex === 2 && (
                  <>
                    <p style={{ clipPath: 'polygon(53% 0, 0 100%, 100% 100%)' }} className='absolute bg-Primary h-[6px] w-[20px] bottom-0 left-[49%]'></p>

                  </>
                )}
              </p>
            </div>

            <div className='mt-[50px] max_xl1460:mt-[30px] flex flex-col gap-[10px] items-center min-h-[230px]'>
              {selectedIndex === 1 && (<>
                {(id1 == 1 ? bebefitsData1 : id1 == 2 ? bebefitsData2 : id1 == 3 ? bebefitsData3 : id1 == 4 ? bebefitsData4 : id1 == 5 ? bebefitsData5 : id1 == 6 ? bebefitsData6 : id1 == 7 ? bebefitsData7 : bebefitsData8).map((item, index4) => (
                  <div className='flex text-center' key={index4}>
                    <div className='text-[18px] font-OpenSansF font-semibold text-Primary'>{item.title1}<span className='font-normal text-Black'>{item.text1}</span></div>
                  </div>
                ))}
                {productData.title1 && (

                  <div className='flex text-center'>
                    <div className='text-[18px] font-OpenSansF font-semibold text-Primary'>{productData.title1}<span className='font-normal text-Black'>{productData.text1}</span></div>
                  </div>
                )}
              </>)}
              {selectedIndex === 2 && (<>
                {(id1 == 1 ? RecData1 : id1 == 2 ? RecData2 : id1 == 3 ? RecData3 : id1 == 4 ? RecData4 : id1 == 5 ? RecData5 :  id1 == 7 ? RecData7 : id1 == 8 ? RecData8: (id1 == 6 && id2 == 1 ? RecData6 :id1 == 6 && id2 == 2 ? RecData10 : id1 == 6 && id2 == 3 ? RecData11 : RecData12)).map((item, index5) => (
                  <div className='flex text-center' key={index5}>
                    <div className='text-[18px] font-OpenSansF font-normal text-Black'>{item}</div>
                  </div>
                ))}
              </>)}
            </div>
          </div>
        </div>

      </div >
    </>
  )
}

export default SingleProdComp

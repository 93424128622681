import React, { useEffect, useState } from 'react'
import Image1 from "../images/Products/Mask1.png"
import Image2 from "../images/Products/Mask2.png"
import Image3 from "../images/Products/Mask3.png"
import Image4 from "../images/Products/Mask4.png"
import Image5 from "../images/Products/Mask5.png"
import Image6 from "../images/Products/Mask6.png"
import Image7 from "../images/Products/Mask7.png"
import Image8 from "../images/Products/Mask8.png"
import HeadingComp from './HeadingComp'
import { useNavigate } from 'react-router-dom'
import ProductCard from './ProductCard'
import { ThreeDots } from 'react-loader-spinner'

const ProdutsMain = () => {
  const navigate = useNavigate()
  const [data,] = useState([
    {
      id: 1,
      name: 'Two Wheeler Engine Oil',
      image: Image1,
    },
    {
      id: 2,
      name: 'Car Engine Oil',
      image: Image2
    },
    {
      id: 3,
      name: 'Tractor Engine Oil',
      image: Image3
    },
    {
      id: 4,
      name: 'Gear Care Oil',
      image: Image4
    },
    {
      id: 5,
      name: 'Hydraulic Oil',
      image: Image5
    },
    {
      id: 6,
      name: 'Brake Oil, Front Fork Oil & Power Steering Oil',
      image: Image6
    },
    {
      id: 7,
      name: 'Heavy Duty Coolant',
      image: Image7
    },
    {
      id: 8,
      name: 'Pump Set Oil',
      image: Image8
    },
  ])
  const handleClick = (id) => {
    if (id === 1 || id === 2 || id === 3 || id===4 || id === 5 || id ===6) {
      navigate(`/products/${id}`)
    }else if(id === 7){
      navigate(`/products/${7}/${1}`)
    }else{
      navigate(`/products/${8}/${1}`)
    }
  }
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (imageSrc) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = resolve;
        image.onerror = reject;
      });
    };

    const loadAllImages = async () => {
      try {
        await Promise.all(data.map(item => loadImage(item.image)));
        setImagesLoaded(true);
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadAllImages();
  }, [data]);

  return (
    <div className='max-w-[1460px] m-auto flex flex-col items-center mt-[100px] max_768:mt-[60px] max_500:mt-[50px] gap-[50px] max_768:gap-[25px] max_500:gap-5 max_xl1460:max-w-[980px] max_980:max-w-[768px] max_768:px-10 max_500:px-5'>
      <div className='text-center'>
        <HeadingComp head1="Our Premium" head2="Lubricant Solutions" />
        <p className='Text18Sans mt-[30px] text-center'>Your trusted partner for unmatched engine performance and longevity. With our commitment to quality and excellence, we ensure that every drop of Trisol lubricant delivers unparalleled protection and efficiency for your engine. From smooth rides to reliable performance, trust Trisol to keep your engine running at its best mile after mile.</p>
      </div>

      {!imagesLoaded ? (<>
        <div className='w-full h-[600px] F_JC_IC'>
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="#004E72"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>

      </>

      ) : (<>
        <div className='grid grid-cols-2 mb-[200px] max_768:grid-cols-1 max_768:mb-[100px] max_500:mb-[50px] gap-y-[50px] max_768:gap-y-5'>
          {data.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ProductCard item={item} handleClick={handleClick} id={index} />
              </React.Fragment>
            )
          })}

        </div>
      </>
      )}

    </div>
  )
}

export default ProdutsMain

import React, { useEffect, useRef, useState } from 'react'
import OutNumImg from '../images/Homepage/OurNumImg.png'
import CommonTwoPartComponent from './CommonTwoPartComponent'
import { CountUp } from 'use-count-up'
import { useIsVisible } from '../hooks/useVisible';

const OurNumber = ({ main }) => {
  const [isVisible, setIsVisible] = useState(false);
  const countRef = useRef()
  const countInView = useIsVisible(countRef)
  useEffect(() => {
    if (countInView) {
      setIsVisible(true)
    }

  }, [countInView])
  return (
    <>
      <div className='w-full bg-light_White my-44 max_1024:my-28 max_500:my-12'>
        <div className='relative max-w-[1460px] m-auto py-24 my-20 max_xl1460:max-w-[1024px] max_1024:py-16 max_880:py-12 ' ref={countRef}>

          <div className='w-full max-w-[1460px] m-auto max_1024:max-w-[40%] max_768:max-w-[45%] max_1024:ml-0 max_1024:px-10 max_500:px-5 max_720:max-w-[100%] max_720:!m-auto  max_500:!ml-0'>
            <p className='Text36Monts text-Primary max_720:text-center max_500:text-start'>Our <span className='text-Secondary_Primary'>Numbers</span></p>
            <div className='mt-[50px] flex gap-[60px] max_xl1460:gap-5 max_1024:flex-wrap max_720:justify-center max_500:mt-[30px] max_500:w-[60%] max_500:justify-start max_400:w-[90%]'>
              <div>
                {/* <p className='Text28Monts'>50+</p> */}
                {isVisible && (<>
                  <div className='Text28Monts'>
                    <CountUp isCounting end={50} duration={3.2} />
                    <span>+</span>
                  </div>
                  </>
                )}
                <p className='Text18Sans max_xl1460:text-[16px] text-gray'>Trained Staffs</p>
              </div>
              <div>
                {/* <CountUpInView start={0} end={20} /> */}
                {isVisible && (
                  <div className='Text28Monts'>
                  <CountUp isCounting end={20} duration={4} />
                  </div>
                )}
                <p className='Text18Sans max_xl1460:text-[16px] text-gray'>Branches</p>
              </div>
              <div>
                {/* <p className='Text28Monts'>1500</p> */}
                {/* <CountUpInView start={15} end={1500} /> */}
                {isVisible && (
                  <div className='Text28Monts'>
                  <CountUp isCounting end={1500} duration={3} />
                  </div>
                )}
                <p className='Text18Sans max_xl1460:text-[16px] text-gray'>Satisfied Clients</p>
              </div>
              <div>
                {isVisible && (
                  <div className='Text28Monts'>
                  <CountUp isCounting end={1000} duration={3.5} />
                  </div>
                )}
                <p className='Text18Sans max_xl1460:text-[16px] text-gray'>Jobs Done</p>
              </div>
            </div>

            {/* MOBILE DEVICE IMAGE */}
            <div className='hidden max_720:block w-full mt-5' style={{ textAlign: '-webkit-center' }}>
              <div className='w-[70%] flex justify-center max_500:w-[90%]'>

                <img src={OutNumImg} alt='Our_numbers' />
              </div>
            </div>
          </div>
          <div className='absolute right-0 top-[-100px] max_1024:right-10 max_1024:w-[50%] max_1024:top-[-62px] max_xl1460:top-[-78px] max_980:w-[53%] max_880:top-[-48px] max_768:top-[-24px] max_720:hidden' data-aos="zoom-in">
            <img src={OutNumImg} alt='Our_numbers' />
          </div>


        </div>
      </div>

      {/* SECTION 2 */}
      {main && (<><CommonTwoPartComponent text="At Trisol, our goal is simple yet ambitious: to be the premier manufacturer and supplier of lubricants and automobile oils, setting the standard for quality, innovation, and sustainability in the industry." head1="The Main Goal of" head2="“TRISOL”" /></>)}


    </>
  )
}

export default OurNumber

import { Route, Routes } from 'react-router';
import './App.css';

import Home from './Pages/Home';
import { ScrollToTop } from './hooks/useScrollTop';
import AboutUs from './Pages/AboutUs';
import Gallary from './Pages/Gallary';
import ContactUs from './Pages/ContactUs';
import Carrer from './Pages/Carrer';
import Products from './Pages/Products';
import ProductPage from './Pages/ProductPage';
import SingleProductPage from './Pages/SingleProductPage';

import AOS from "aos";
import "aos/dist/aos.css";
const App = () => {
  // useEffect(() => {
    AOS.init();
    AOS.refresh();
    AOS.refreshHard()
  // }, []);
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/gallary" element={<Gallary />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/career" element={<Carrer />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id1" element={<ProductPage />} />
        <Route path="/products/:id1/:id2" element={<SingleProductPage />} />
      </Routes>
    </>
  );
}

export default App;


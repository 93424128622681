import React, { useState,useEffect } from 'react'
import ProductImg from '../images/Info/Product.png'
import InnovativeImg from '../images/Info/Innovative.png'
import SustainabilityImg from '../images/Info/Sustainability.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules';
import AOS from "aos";
import "aos/dist/aos.css";

const Info = () => {
  const [infoData] = useState([
    {
      id: 1,
      image: ProductImg,
      title: 'Product Excellence',
      text: 'Trisol is renowned for unwavering quality throughout manufacturing. We select premium materials and maintain strict controls, ensuring reliability & durability.'
    },
    {
      id: 2,
      image: InnovativeImg,
      title: 'Innovative Solutions',
      text: 'Trisol develops cutting-edge formulations for superior automotive lubricants, optimizing performance and longevity.'
    },
    {
      id: 3,
      image: SustainabilityImg,
      title: 'Sustainability Commitment',
      text: 'Trisol is committed to environmental responsibility and sustainability, prioritizing eco-friendly practices and technologies to minimize our footprint.'
    },
  ])
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    AOS.refreshHard()
  }, []);

  return (
    <div className='bg-Light_Secondary px-10 max_500:px-5'>
      <div className=' w-full max-w-[1460px] m-auto grid grid-cols-3 py-20 gap-[30px] max_xl1460:max-w-[1024px] max_1024:hidden'>
        {infoData.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <div className='' data-aos="fade-right">
                <div className='w-[100px] h-[100px]'>
                  <img src={data.image} alt='ghg' className='w-full h-full' />
                </div>
                <p className='text-[24px] max_xl1460:text-[22px] font-MontserratF font-medium mt-[30px]'>{data.title}</p>
                <p className='Text18Sans mt-5'>{data.text}</p>
              </div>
            </React.Fragment>
          )
        })}
      </div>

      <div className='hidden max_1024:block'>
        <div className='flex justify-center px-10 max_500:px-5 py-[50px] max_500:py-6'>

          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Pagination,Autoplay]}
            className="mySwiperInfo"
            loop={true}
          >
            {infoData.map((data, idx) => (
              <SwiperSlide key={idx} style={{ background: "transparent" }}>
                <div className='flex flex-col items-center'>
                  <div className='w-[100px] h-[100px] max_500:w-[80px] max_500:h-[80px]'>
                    <img src={data.image} alt='ghg' className='w-full h-full' />
                  </div>
                  <p className='text-[20px] font-MontserratF font-medium mt-[30px] max_500:mt-5 text-center'>{data.title}</p>
                  <p className='Text18Sans text-[16px] mt-5 text-center'>{data.text}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Info

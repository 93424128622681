import React, { useState } from 'react'
import CommonCard from './CommonCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules';
import { useWindowWidthObserver } from '../hooks/useWindowWidth'
import { CustomerIcon, EnvironmentalIcon, InnovativeIcon, QualityIcon } from '../images/icons'

const HowWeWork = () => {
  const [infoData] = useState([
    {
      id: 1,
      image: <QualityIcon />,
      title: 'Quality Assurance',
      text: 'Trisol maintains strict quality control measures from sourcing raw materials to production, ensuring our products meet or exceed industry standards.'
    },
    {
      id: 2,
      image: <InnovativeIcon />,
      title: 'Innovative Formulations',
      text: 'We leverage cutting-edge technology and ongoing research to develop advanced formulations that optimize engine performance and prolong equipment life.'
    },
    {
      id: 3,
      image: <EnvironmentalIcon />,
      title: 'Environmental Responsibility',
      text: 'Trisol prioritizes eco-friendly practices and sustainable technologies to minimize our environmental footprint, supporting a cleaner future.'
    },
    {
      id: 4,
      image: <CustomerIcon />,
      title: 'Customer-Centric Approach',
      text: 'With personalized service and support, we work closely with customers to understand their unique needs and provide tailored solutions, backed by a reliable supply chain for timely delivery worldwide.'
    },
  ])
  const [storeIndex,setStoreIndex] = useState(null)
  const windowWidth = useWindowWidthObserver();
  return (
    <>

      <div className='w-full max-w-[1460px] max_xl1460:max-w-[1024px] m-auto my-[100px] max_768:my-[60px] max_500:my-[50px]'>
        <p className='Text36Monts text-Primary text-center'>How <span className='text-Secondary_Primary'>We Work</span></p>
        <div className='grid grid-cols-4 gap-[30px] mt-[50px] max_xl1460:grid-cols-2 max_1024:hidden'>
          {infoData.map((data, index) => {
            return (
              <React.Fragment key={index} >
                <CommonCard data={data} storeIndex={storeIndex} setStoreIndex={setStoreIndex} index={index} />
              </React.Fragment>
            )
          })}
        </div>
        <div className='hidden max_1024:block'>
          <div className='flex justify-center px-10 max_500:px-5 py-[50px] max_500:py-[40px] max_500:mx-5 max_350:mx-0'>
            <Swiper
              slidesPerView={windowWidth > 500 ? 2 : 1}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiperInfo"
              loop={true}
            >
              {infoData.map((data, idx) => (
                <SwiperSlide key={idx} style={{ background: "transparent" }}>
                  <CommonCard data={data} height={true} storeIndex={storeIndex} setStoreIndex={setStoreIndex} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

    </>
  )
}

export default HowWeWork

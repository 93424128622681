import React from 'react'
import { CustomerSatisfactionIcon, ImprovedIcon, PerformanceIcon, ProtectionIcon, QualityIcon } from '../Icons/icon'

const ServiceComp = () => {
  return (
    <div className='flex gap-4 max_xl1460:gap-2'>
      <div className='flex flex-col items-center gap-6'>
        <div className='w-[60px] h-[60px] max_xl1460:w-[50px] max_xl1460:h-[50px]'>
          <QualityIcon />
        </div>
        <p className='Text18Sans text-[16px] max_xl1460:text-[15px] text-center'>Quality
Assurance</p>
      </div>
      <div className='flex flex-col items-center gap-6'>
        <div className='w-[60px] h-[60px] max_xl1460:w-[50px] max_xl1460:h-[50px]'>
          <ImprovedIcon />
        </div>
        <p className='Text18Sans text-[16px] max_xl1460:text-[15px] text-center'>Improved Engine
          Performance</p>
      </div>
      <div className='flex flex-col items-center gap-6'>
        <div className='w-[60px] h-[60px] max_xl1460:w-[50px] max_xl1460:h-[50px]'>
          <ProtectionIcon />
        </div>
        <p className='Text18Sans text-[16px] max_xl1460:text-[15px] text-center'>Protection
          & Durability</p>
      </div>
      <div className='flex flex-col items-center gap-6'>
        <div className='w-[60px] h-[60px] max_xl1460:w-[50px] max_xl1460:h-[50px]'>
          <CustomerSatisfactionIcon />
        </div>
        <p className='Text18Sans text-[16px] max_xl1460:text-[15px] text-center'>Customer
          Satisfaction</p>
      </div>
      <div className='flex flex-col items-center gap-6'>
        <div className='w-[60px] h-[60px] max_xl1460:w-[50px] max_xl1460:h-[50px]'>
          <PerformanceIcon />
        </div>
        <p className='Text18Sans text-[16px] max_xl1460:text-[15px] text-center'>Performance
          Guarantee</p>
      </div>
    </div>
  )
}

export default ServiceComp

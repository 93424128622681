import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import CareerOppor from '../Components/CareerOppor'

const Carrer = () => {
  return (
    <>
     <Navbar />
      <div className='w-full max-w-[1212px] max_xl1460:max-w-[1024px] m-auto my-[100px] max_768:my-[60px] max_500:my-[50px] text-center max_1024:px-10 max_500:px-5'>
      <p className={`Text36Monts text-Primary `} data-aos="zoom-in">Explore   <span className='text-Secondary_Primary'>Career Opportunities </span>at Trisol</p>
        <p className='Text18Sans mt-[30px]'>Join the Trisol Family: Where Innovation Meets Opportunity! Ready to embark on a rewarding career journey with a leading lubricants and oils manufacturer? Explore exciting opportunities to grow professionally while making a difference in the automotive industry. Take the first step towards an enriching career by filling out the form below. Your future awaits at Trisol!</p>
        
        <CareerOppor />

      </div>
      <Footer />
    </>
  )
}

export default Carrer

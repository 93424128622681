import React from 'react'
import Navbar from '../Components/Navbar'

import GallaryBanner from '../Components/GallaryBanner'
import Footer from '../Components/Footer'

const Gallary = () => {
  return (
    <div>
      <Navbar />

      <GallaryBanner />

      <Footer />
    </div>
  )
}

export default Gallary

import React from 'react'

const InputTextArea = ({placeholder,name,value,handleOnChange,error}) => {
  return (
    <div className='relative'>
      <textarea className='w-full px-4 py-[11px] bg-[#F5F5F5] rounded-[5px] focus:outline-none Text18Sans text-gray resize-none' placeholder={placeholder} rows={5} onChange={handleOnChange} value={value} name={name}   />
      <p className='absolute text-[11px] text-red-500'>{error}</p>
    </div>
  )
}

export default InputTextArea

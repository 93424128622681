import React, { useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ReactComponent as Left } from '../images/Homepage/Left.svg'
import { ReactComponent as Right } from '../images/Homepage/Right.svg'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import ProductCard from './ProductCard';
import { productList } from './Data';
import { useWindowWidthObserver } from '../hooks/useWindowWidth';

const SwipeProduct = () => {
  const { id1 } = useParams();
  const navigate = useNavigate()
  const findProduct = productList.find(product => product.id == id1);

  const [selectedProducts, setSelectedProducts] = useState(findProduct)
  const sliderRef = useRef()
  const handleNext = () => {
    sliderRef.current.swiper.slideNext()
  }
  const handlePrev = () => {
    sliderRef.current.swiper.slidePrev()
  }
  const windowWidth = useWindowWidthObserver();
  const handleClick = (idx) => {
    navigate(`/products/${id1}/${idx}`)
  }
  return (
    <>
      {id1 && (id1 === '1' || id1 === '2' || id1 === '3' || id1 === '4' || id1 === '5' || id1 === '6') && (

        <div className='max-w-[1460px] m-auto mt-[100px] max_768:mt-[60px] max_500:mt-[50px] max_xl1460:max-w-[980px] max_980:max-w-[768px] max_768:px-10 max_500:px-5 mb-[100px]'>
          <div>
            <div className='flex justify-between'>
              <p className='text-[22px] font-MontserratF font-medium text-Primary max_768:text-[20px]'>Similar Products</p>
              <div className='flex gap-5'>
                <div className='F_JC_IC w-[40px] h-[40px] max_xl1460:w-8 max_xl1460:h-8 bg-white border-[1px] border-Primary rounded-[5px] cursor-pointer' onClick={handlePrev}>
                  <Right />
                </div>
                <div className='F_JC_IC w-[40px] h-[40px] max_xl1460:w-8 max_xl1460:h-8 bg-white border-[1px] border-Primary rounded-[5px] cursor-pointer' onClick={handleNext} >
                  <Left />
                </div>
              </div>
            </div>




            <div className='mt-5'>
              <Swiper
                ref={sliderRef}
                slidesPerView={windowWidth > 980 ? 3 : windowWidth > 500 ? 2 : 1}
                spaceBetween={0}
                pagination={{
                  clickable: false,
                }}
                // modules={[Pagination]}
                className="mySwiper"
                loop={true}
              >

                {selectedProducts?.allProducts.map((item, index) => {
                  return (
                    <SwiperSlide key={index} style={{ background: "transparent" }}>
                      <ProductCard item={item} handleClick={handleClick} id={index} isSwipe />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SwipeProduct

import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import BannerPage from '../Components/BannerPage'
import Info from '../Components/Info'
import AboutSection from '../Components/AboutSection'
import HowWeWork from '../Components/HowWeWork'
import OurNumber from '../Components/OurNumber'
import Available from '../Components/Available'
import Footer from '../Components/Footer'
import { FBIcon, INIcon, LNIcon, WPIcon, YTIcon } from '../images/icons'
import GetInTouch from '../Components/GetInTouch'
import MapChart from '../Components/MapChart'
import HeadingComp from '../Components/HeadingComp'

const Home = () => {
  const [socialIndex, setSocialIndex] = useState(null)
  return (
    <>
      <div className='relative'>

        <Navbar />
        <BannerPage />
        <Info />

        <AboutSection />

        <HowWeWork />

        <OurNumber main={true} />

        <Available />

        {/* <GetInTouch /> */}
        <div>
        <div className='w-full max-w-[1212px] max_xl1460:max-w-[980px] max_1024:px-10 max_500:px-5 m-auto my-[100px] max_500:my-[50px] max_768:my-[60px] text-center'>
          <HeadingComp head1="Trisol :" head2="Lubricants Across Continents" />

        </div>
        <div className='max-w-[1380px] max_xl1460:max-w-[980px] max_980:px-10 max_500:px-5 m-auto mt-[50px] max_500:mt-[30px]'>
          <MapChart />
        </div>
      </div>

        <Footer />
        <div className='fixed top-[36%] left-0 bg-Primary flex flex-col z-50 w-[60px] items-center rounded-r-[10px] max_768:hidden'>
          <div className='py-[18px] cursor-pointer' onMouseEnter={() => setSocialIndex(1)} onMouseLeave={() => setSocialIndex(null)}>
            <FBIcon color={`${socialIndex === 1 ? '#B47922' : '#fff'}`} />
          </div>
          <div className='w-full h-[1px] bg-white'></div>
          <div className='py-[18px] cursor-pointer' onMouseEnter={() => setSocialIndex(2)} onMouseLeave={() => setSocialIndex(null)}>
            <WPIcon color={`${socialIndex === 2 ? '#B47922' : '#fff'}`} />
          </div>
          <div className='w-full h-[1px] bg-white'></div>
          <div className='py-[18px] cursor-pointer' onMouseEnter={() => setSocialIndex(3)} onMouseLeave={() => setSocialIndex(null)}>
            <INIcon color={`${socialIndex === 3 ? '#B47922' : '#fff'}`} />
          </div>
          <div className='w-full h-[1px] bg-white'></div>
          <div className='py-[18px] cursor-pointer' onMouseEnter={() => setSocialIndex(4)} onMouseLeave={() => setSocialIndex(null)}>
            <LNIcon color={`${socialIndex === 4 ? '#B47922' : '#fff'}`} />
          </div>
          <div className='w-full h-[1px] bg-white'></div>
          <div className='py-[18px] cursor-pointer' onMouseEnter={() => setSocialIndex(5)} onMouseLeave={() => setSocialIndex(null)}>
            <YTIcon color={`${socialIndex === 5 ? '#B47922' : '#fff'}`} />
          </div>
        </div>

      </div>
    </>
  )
}

export default Home
